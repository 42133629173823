<template>
  <section id="banner">
    <div class="banner_header relative">
      <img src="../../assets/img/aboutus/banner_bg.png" alt="" />
      <div class="header_text">
        <p class="header_text_p">为千万级中小微企业</p>
        <p class="header_text_p">提供效率化的增值专业服务</p>
        <p class="header_btn">简便、快捷、高效的财税管理支撑</p>
      </div>
    </div>
  </section>

  <section id="tips">
    <div class="tips relative">
      <ul class="absolute tips_ul flex">
        <li class="flex" v-for="(item, index) in state.tipsList" :key="index">
          <img :src="item.img" alt="" />
          <div class="tips_li_text">
            <p>{{ item.num }}</p>
            <p>{{ item.text }}</p>
          </div>
        </li>
      </ul>
    </div>
  </section>

  <section id="content_text">
    <div class="content_text">
      <p class="content_tit">专注财税数字化的专业服务集团</p>
      <div class="content_p_div">
        <p>
          海闻科技有限公司创立于2008年12月，是国内首家企业端税务数字化管理平台整体解决方案供应商，具备深厚的财税专业咨询服务能力。
        </p>
        <p>
          公司自成立以来，一直以“IT+T（数字化+财税服务）”的创新服务模式戮力前行，专注于企业端的税务数字化管理平台建设，为客户提供大企业集团税务数字化管理体系设计与搭建专业咨询、企业端税务管理数字化平台定制开发与系统集成、现有业务系统涉税改造IT咨询、税务管理数字化平台部署实施、专业培训等一站式整体解决方案。
        </p>
        <p>
          公司历时多年研发的系列产品，采用主流技术架构，得到许多客户高度认可，现正广泛应用于多行业大中型客户。近年打造的面向中小微企业财税服务的财税智享云、票靓微报销等SaaS化财税服务云，为2万余户中小微企业带来简便、快捷、高效的财税管理支撑。
        </p>
        <p>
          公司注册资本1亿元，现拥有400名税务专业顾问人员及160余名研发人员，其中中国注册税务师205名。服务机构分布于广州、北京、上海、深圳、东莞、珠海、成都、天津、山东、重庆等主要城市，为客户提供全方位、跨区域的服务支撑。
        </p>
        <p>
          展望未来，我们秉承“思想引领未来，专业创造价值”的经营理念，坚持以税务专业思想为引领，专注、专业、专职，不断地应合各类企业的税务管理需求，努力实现企业税务管理价值最优化。我们将以做中国领先的企业端税务数字化管理平台解决方案提供商和财税云服务平台为目标，深植耕耘于企业和个人用户税务管理领域。
        </p>
      </div>
      <businesscard></businesscard>
    </div>
  </section>

  <div style=" margin: 0 auto 80px; ">
    <div class="question_t relative">
      <span class=""></span><span class="absolute">联系我们</span>
    </div>
    <div class="flex section_box">
      <div class="section">
      <div class="header">海闻科技有限公司</div>
      <div class="text">
        联系地址： 广州市天河区体育东路122号羊城国际商贸中心大厦东塔24层
      </div>
      <div class="text">营销热线： 400-059-6663</div>
      <div class="text">电&nbsp;&nbsp; 话：（020）8754 7480</div>
      <div class="text">传&nbsp;&nbsp; 真：（020）8754 7480</div>
      <div class="text">邮&nbsp;&nbsp; 编： 510620</div>
      <div class="text">
        营销E-mail ：<a href="mailto:haiwen@ccitc.net.cn">market@ccitc.net.cn</a
        >
      </div>
    </div>
    <div class="section">
      <div class="header">海闻科技有限公司北京分公司</div>
      <div class="text">联系地址：北京市海淀区西三环北路87号1至2层115</div>
      <div class="text">营销热线： 400-059-6663</div>
      <div class="text">联系电话：（010）8391 5280</div>
      <div class="text">
        营销E-mail ：<a href="mailto:haiwen@ccitc.net.cn">market@ccitc.net.cn</a
        >
      </div>
    </div>
    <div class="section">
      <div class="header">海闻科技有限公司上海分公司</div>
      <div class="text">
        联系地址：上海市普陀区中山北路3000号长城大厦2317室
      </div>
      <div class="text">营销热线： 400-059-6663</div>
      <div class="text">联系电话：（020）3887 1960</div>
      <div class="text">
        营销E-mail ：<a href="mailto:haiwen@ccitc.net.cn">market@ccitc.net.cn</a
        >
      </div>
    </div>
    <div class="section">
      <div class="header">海闻科技有限公司深圳分公司</div>
      <div class="text">
        联系地址：深圳市福田区深南大道6031号杭钢富春商务大厦1912室
      </div>
      <div class="text">营销热线： 400-059-6663</div>
      <div class="text">联系电话：（0755）8826 5588</div>
      <div class="text">
        营销E-mail ：<a href="mailto:haiwen@ccitc.net.cn">market@ccitc.net.cn</a
        >
      </div>
    </div>
    </div>
    
  </div>
  <registration></registration>
</template>

<script setup lang="ts">
import { reactive, watchEffect } from "vue";
import getAssetsFile from "/@/assets/img/image_js/image_js";
import registration from "./registration.vue";
import businesscard from "./businesscard2.vue";

const state = reactive({
  tipsList: [
    {
      img: getAssetsFile.getAssetsFile("/aboutus/al.png"),
      text: "大企业税务信息化案例",
      num: "100+",
    },
    {
      img: getAssetsFile.getAssetsFile("/aboutus/kh.png"),
      text: "财税云服务客户案例",
      num: "2W+",
    },
    {
      img: getAssetsFile.getAssetsFile("/aboutus/yf.png"),
      text: "财税IT产品研发经验",
      num: "15+",
    },
    {
      img: getAssetsFile.getAssetsFile("/aboutus/rj.png"),
      text: "财税IT软件著作权",
      num: "100+",
    },
  ],
});
</script>

<style scoped>
.flex {
  display: flex;
}
.relative {
  position: relative;
}
.relative > img {
  width: 100%;
}
.relative > div {
  position: absolute;
  z-index: 1;
}
.absolute {
  position: absolute;
}

.banner_header > img {
  width: 1920px;
  height: 480px;
  margin: 0 auto;
}
.header_text {
  width: 1200px;
  left: 50%;
  top: calc(50% - 31px);
  transform: translate(-50%, -50%);
  /* margin-top: 58px; */
}
.header_text > .header_text_p {
  text-align: center;
  font-size: 44px;
  font-family: SourceHanSansCN-Bold, SourceHanSansCN;
  font-weight: bold;
  color: #ffffff;
  line-height: 58px;
  text-shadow: 0px 5px 20px rgba(0, 146, 218, 0.54);
}
.header_btn {
  width: 332px;
  height: 53px;
  background: rgba(255, 255, 255, 0.7);
  border-radius: 27px;
  backdrop-filter: blur(1px);
  margin: 0 auto;
  margin-top: 40px;
  color: #035c84 !important;
  font-size: 18px !important;
  line-height: 53px;
  text-align: center;
}

.tips {
  height: 150px;
}
.tips_ul {
  width: 1200px;
  height: 190px;
  background: #ffffff;
  box-shadow: 0px 0px 31px 0px rgba(144, 160, 179, 0.16);
  top: -63px;
  justify-content: space-around;
  padding: 58px 0 54px 0;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 1;
}
.tips_ul > li:nth-last-child(1) {
  border: none;
}
.tips_ul > li {
  border-right: 1px solid #e7e7e7;
  align-items: flex-start;
  padding: 0 38px;
}
.tips_ul img {
  width: 40px;
  height: 40px;
  margin-top: 7px;
  margin-right: 15px;
}
.tips_li_text > p:nth-child(1) {
  font-size: 48px;
  font-family: Gotham-Medium, Gotham;
  font-weight: 500;
  color: #333333;
  line-height: 58px;
}
.tips_li_text > p:nth-child(2) {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 20px;
  /* margin-top: 8px; */
}

.content_text {
  width: 1200px;
  margin: 0 auto;
  padding-top: 30px;
  padding-bottom: 80px;
  font-family: PingFangSC-Semibold, PingFang SC;
}
.content_tit {
  font-size: 28px;
  font-weight: 600;
  color: #333333;
  line-height: 40px;
  text-align: center;
}
.content_p_div {
  margin-top: 27px;
  margin-bottom: 48px;
}
.content_p_div > p {
  width: 1200px;
  font-size: 16px;
  font-weight: 400;
  color: #333333;
  line-height: 32px;
  margin-bottom: 28px;
}
div,
li,
p,
span {
  cursor: context-menu;
}
.header {
  font-weight: 600;
  font-size: 20px;
  color: #333333;
  margin-bottom: 5px;
}

.text {
  font-size: 16px;
  color: #666666;
  font-weight: 400;
  margin-top: 3px;
}

/* .section {
  margin-top: 30px;
} */
.question_t {
  width: 144px;
  margin: 20px auto 60px;
}
.question_t > span:nth-child(1) {
  display: inline-block;
  width: 150px;
  height: 6px;
  background: linear-gradient(138deg, #c1faf7 0%, #33cdce 100%);
}
.question_t > span:nth-child(2) {
  display: inline-block;
  left: 0;
  bottom: 2px;
  font-size: 36px;
  font-family: SourceHanSansCN-Bold, SourceHanSansCN;
  font-weight: bold;
  color: #27364e;
  line-height: 54px;
}

.section_box {
  width: 1200px;
  min-width: 1200px;
  height: 483px;
  background: #FFFFFF;
  box-shadow: 0px 6px 40px 0px rgb(194 203 207 / 51%);
  padding: 40px;
  box-sizing: border-box;
  flex-wrap: wrap;
  margin: 0 auto;
}
.section_box>div {
  width: 50%;
  border-right: 1px solid #E6EFF4;
  border-bottom: 1px solid #E6EFF4;
  /* padding-top: 30px; */
}
.section_box>div:nth-child(3) {
  border-bottom: 0;
  padding-top: 30px;
}
.section_box>div:nth-child(2) {
  padding-left: 40px;
  border-right: 0;
}
.section_box>div:nth-child(4) {
  border-bottom: 0;
  padding-top: 30px;
  padding-left: 40px;
  border-right: 0;
}
</style>