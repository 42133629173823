import {createRouter, createWebHashHistory} from 'vue-router';
// 1. 定义路由组件.
import Index from "/@/views/index/Index.vue";
import ConcatUs from "/@/views/index/ConcatUs.vue";
import AboutUs from "/@/views/index/aboutus.vue";
import Scene from "/@/views/index/scene.vue";
import Price from "/@/views/index/Price.vue";
import Thali from "/@/views/index/thali.vue";
import Buy from "/@/views/index/buy.vue";
import Dilatation from "/@/views/index/dilatation.vue";
// 2. 定义一些路由
// 每个路由都需要映射到一个组件。
const routes = [
    {path: '/', component: Index, name: '首页'},
    {path: '/Scene', component: Scene, name: '解决场景'},
    {path: '/Thali', component: Thali, name: '购买套餐'},
    {path: '/Price', component: Price, name: '价格'},
    {path: '/Buy', component: Buy, name: '购买'},
    {path: '/Dilatation', component: Dilatation, name: '购买扩容包'},
    {path: '/ConcatUs', component: ConcatUs, name: '联系我们'},
    {path: '/AboutUs', component: AboutUs, name: '关于我们'},
]

// 3. 创建路由实例并传递 `routes` 配置
export const router = createRouter({
    // 4. 内部提供了 history 模式的实现。为了简单起见，我们在这里使用 hash 模式。
    history: createWebHashHistory(),
    routes, // `routes: routes` 的缩写,
    // 是否应该禁止尾部斜杠。默认为假
    strict: true,
    scrollBehavior: () => ({left: 0, top: 0}),
})


