<template>
  <div class="registration">
      <img src="../../assets/img/registration_bg.png" alt="" class="bg">
      <div class="content">
          <p class="title">告别纸质传统报销模式，开启全线上报销时代</p>
          <p class="tit">满足个人、个体工商户、企业用户一站式报销</p>
          <div class="btn_box">
              <div @click="login">免费试用</div>
              <div style="background:#F45B41;margin-right:0" @click="mikecrm">登记享优惠</div>
          </div>
      </div>
  </div>
</template>


<script setup lang="ts">
import {useRouter} from "vue-router";
import {onBeforeUnmount, reactive, watchEffect} from "vue";
import Bus from "/@/utils/bus";
import {ROUTER_CACHE_KEY} from "/@/enums/cacheEnum";

const state = reactive({
})

function mikecrm() {
    console.log(123);
  window.open('http://seapower.mikecrm.com/9jGYiDd', "_blank")
}
function login() {
  window.open('https://baoxiao666.com/pl', "_blank")
}
</script>

<style scoped>
.registration {
    /* width: 100vw; */
    min-width: 1200px;
    position: relative;
    /* margin-top: 50px; */
    /* height: 220px; */
}
.bg {
    /* position: absolute;
    top: 0; */
    width: 1920px;
    height: 350px;
}
.content {
    text-align: center;
    width: 1200px;
    padding: 80px 0;
    box-sizing: border-box;
    z-index: 1;
    position: absolute;
    top: 0;
    height: 100%;
    left: 50%;
    transform: translate(-50%,0);
}
.title {
    font-size: 36px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 550;
    color: #FFFFFF;
    line-height: 54px;
}
.tit {
    font-size: 20px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 28px;
    margin-top: 25px;
}
.btn_box {
    display: flex;
    align-content: center;
    justify-content: center;
    margin-top: 34px;
}
.btn_box>div {
    /* padding: 8px 27px; */
    color: #FFFFFF;
    font-size: 16px;
    margin-right: 20px;
    background: #33CDCE;
    width: 150px;
    height: 40px;
    background: #33CDCE;
    border-radius: 4px;
    line-height: 40px;
    text-align: center;
}
</style>