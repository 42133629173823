<template>
  <section id="header_banner">
    <div class="header_banner relative" style="overflow:hidden">
      <img src="../../assets/img/buy/thail_bg.png" alt="" style="max-width:inherit;" />
      <!-- <div class="header_banner_text">
          <p>票靓·专业版</p>
          <p>更全面的企业财税管理平台</p>
          <p>——&nbsp;&nbsp;套餐购买&nbsp;&nbsp;——</p>
      </div> -->
    </div>
  </section>
  <section id="version">
    <div class="version relative">
      <ul class="flex version_ul absolute">
        <li v-for="(item, index) in versionList.data.packagelist" :key="index">
          <p class="version_t">{{ item.packageName }}</p>
          <p class="version_t2">
            <span>¥</span>
            <span>{{ item.packageFinalBasePrice }}</span>
            <span>/年</span>
          </p>
          <p class="version_t3">原价：<span>¥{{item.packagePrice}}</span></p>
          <ul class="version_ul2">
            <li class="version_li">
              <div class="flex">
                <p>发票仓容量</p>
                <p>
                  {{
                    item.specsList ? item.specsList[0].invoicesQuantity : 0
                  }}张
                </p>
              </div>
              <div class="flex">
                <p>建议使用人数</p>
                <p>{{ item.specsList ? item.specsList[0].specsName : 0 }}</p>
              </div>
            </li>
          </ul>
          <div class="version_btn" @click="state.mouseShow=!state.mouseShow">立即购买</div>
        </li>
      </ul>
    </div>
  </section>

  <section id="question">
    <div class="question">
      <div class="question_t relative"><span class=""></span><span class="absolute">功能优势</span></div>
      <div class="question_img_box">
        <ul class="flex question_img_ul">
          <li class="flex question_img_li" v-for="(item, index) in state.quertion1" :key="index">
            <div class="flex">
              <img :src="item.img" alt="" class="question_img" />
              <div class="question_img_right">
                <p>{{item.tit}}</p>
                <ul>
                  <li v-for="(item2, index2) in item.liList" :key="index2">
                    • {{item2}}
                  </li>
                </ul>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </section>
  <section id="questions">
      <div class="questions">
          <div class="question_t relative"><span class=""></span><span class="absolute">购买问题</span></div>
          <ul>
              <li class="question_content" v-for="(item, index) in state.question2" :key="index">
                  <p>
                    <span style="color:#D48058">Q：</span>
                    {{item.q}}
                  </p>
                  <p v-html="item.a"></p>
              </li>
          </ul>
      </div>
  </section>

    <div class="modules_view" v-if="state.mouseShow">
    <div>
      <p style="cursor: pointer" @click="state.mouseShow = false">×</p>
      <p> <span style="font-size: 18px;font-weight:400;color:#999;">请打开手机微信扫描下方二维码</span> <br/>前往 <span style="color:#41C7C0">票靓小程序</span>下单付款</p>
      <img src="../../assets/img/vipPackage.png" alt="" />
      <!-- <div @click="state.mouseShow = false">关闭</div> -->
    </div>
  </div>

  <buybottom></buybottom>
</template>

<script setup lang="ts">
import { reactive, watchEffect } from "vue";
import getAssetsFile from "/@/assets/img/image_js/image_js";
import buybottom from './buybottom.vue';
import axios from "/@/utils/request";

const state = reactive({
  versionList: [],
  mouseShow: false,
  quertion1:[
      {
          img:getAssetsFile.getAssetsFile("/buy/function.png"),
          tit:'功能丰富全面',
          liList:[
              '涵盖发票管理、费用审批、企业开票、认证、财务管理等功能。',
              '支持全电发票涉税业务流程和企业商业行为的融合，助力企业智慧税务和票稅数字化实现。'
          ]
      },{
          img:getAssetsFile.getAssetsFile("/buy/move.png"),
          tit:'数据一步迁移',
          liList:[
              '选择升级专业版套餐后，票靓将您的企业数据一步迁移至专业版。'
          ]
      },{
          img:getAssetsFile.getAssetsFile("/buy/update.png"),
          tit:'功能持续更新',
          liList:[
              '功能迭代持续更新中，专业版用户无须额外付费即可使用最新功能。'
          ]
      },{
          img:getAssetsFile.getAssetsFile("/buy/change.png"),
          tit:'版本一键切换',
          liList:[
              '如票靓·微报销版的功能及售卖方式更符合您企业的需求，您可切回继续使用。'
          ]
      },
  ],
  question2:[
      {
          q:'如何购买和使用权益套餐?',
          a:'票靓·企业版使用需要先创建企业平台账号，票靓提供企业平台账号级别的统一企业管理功能。票靓·微报销版本无需购买套餐，只需按需购买票仓扩容包即可。无需安装，即可实现随时移动报销。票靓·专业版享有更多企业管理功能，为企业平台账号购买套餐成功后，可直接进行使用。'
      },
      {
          q:'订单支持哪些付款方式?',
          a:'当前支持微信支付和对公付款两种付款方式<br/>1. 微信支付: 订单有效期内 (当前订单有效期为7天)，在订单支付页面选择微信支付>跳转进微信支付页面付款即可完成。<br/>2. 对公付款: 若您采用对公付款的方式，请添加我们的客服企业微信，并于订单有效期内完成对公支付，在对公付款时备注您的订单编号。将订单编号及对公支付的信息发给客服，我们将为您开通企业VIP。'
      },
      {
          q:'购买套餐/扩容包后如何获取发票?',
          a:'票靓提供增值税电子普通发票和增值税专用发票。<br/>如您需要获取增值税专用发票，请联系客服开具;如您需要获取增值税电子普通发票，请在确认订单时选择开具发票，或在订单完成后，在“个人中心”>“发票服务”功能开具发票。'
      },
      {
          q:'套餐/扩容包如何购买?',
          a:'1. 续费套餐: 如您套餐过期后想要升级套餐可直接选择符合您需求的新套餐购买;<br/>2. 票仓扩容: 在套餐权益有效内，如需调整票仓容量，可通过购买扩容包的方式扩充企业票仓容量，详见“扩容包购买”模块。'
      },
      {
          q:'企业有个性化需求怎么办?',
          a:'票靓支持对本产品所有功能进行企业私有化部署/个性化定制。支持企业系统集成 、可专属定制业务功能、具有丰富的数据接口 、专享数据私有化方案。详情请咨询客服，享受一对一专属服务支持。'
      },
      {
          q:'企业付费权益期结束后还能继续使用吗?',
          a:'付费权益期结束后，票靓会保存您之前的数据支持您对历史数据进行查询。如需继续使用VIP功能，请进行续费或升级，'
      },
      {
          q:'套餐有效期是多久?',
          a:'票靓采取年付费制，企业套餐有效期自购买之日起一年自然日范围内有效，例如您于2022年8月26日购买的套餐，将于2023年8月27日00:00:00失效。'
      },
  ]
});

// let packageQueryResult: any = reactive({ data: {} });
// let mouseShow = reactive({
//   mouseShow: false,
//   listActive: 0,
//   scrollStart: false,
//   scrollLeft: 0,
// });
let versionList: any = reactive({ data: {} });
axios
  .get("/order/orderPackage/packageQuery")
  .then((res) => {
    versionList.data = res.result
  })
  .catch((err) => {
    console.log(err);
  });
</script>

<style scoped lang='less'>
div,
li,
p,
span {
  cursor: context-menu;
}
.flex {
  display: flex;
}
.relative {
  position: relative;
}
.relative > img {
  width: 100%;
}
.relative > div {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.header_banner>img {
  width: 1920px;
  height: 310px;
  margin: 0 auto;
}
.absolute {
  position: absolute;
}

.version {
  height: 320px;
  background: #f9fdff;
}
.version_ul {
  justify-content: space-between;
  width: 1200px;
  top: -55px;
  left: 50%;
  transform: translate(-50%, 0);
}
.version_ul > li {
  width: 276px;
  height: 332px;
  background: #FFFFFF;
  box-shadow: 0px 0px 40px 0px rgba(34,34,34,0.05);
  padding: 28px;
  box-sizing: border-box;
}
.version_ul > li > p {
  text-align: center;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
}
.version_t {
  font-size: 24px;
  font-weight: 400;
  color: #27364e;
  line-height: 33px;
  margin-top: 10px;
}
// .version_t2 {
//   margin: 0 0 12px;
// }
.version_t2 > span:nth-child(1) {
  font-size: 32px;
  font-family: DINAlternate-Bold, DINAlternate;
  font-weight: bold;
  color: #BC5F33;
}
.version_t2 > span:nth-child(2) {
  font-size: 48px;
  font-family: DINAlternate-Bold, DINAlternate;
  font-weight: bold;
  color: #BC5F33;
}
.version_t2 > span:nth-child(3) {
  font-size: 22px;
  font-weight: 400;
  color: #999;
  line-height: 30px;
  margin: 15px 0;
}

.version_t3 {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    line-height: 20px;
    margin-top: -5px;
    margin-bottom: 16px;
}
.version_t3>span {
    text-decoration: line-through;
}
/* .version_li {
    justify-content: space-between;
} */
.version_li > div {
  justify-content: space-between;
}
.version_li > div > p {
  font-size: 14px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  line-height: 28px;
}
.version_li > div > p:nth-child(1) {
  color: #666666;
}
.version_li > div > p:nth-child(2) {
  color: #999999;
}

.version_btn {
  width: 168px;
  height: 42px;
  // background: #33CDCE;
  border-radius: 4px;
  margin: 0 auto;
  margin-top: 20px;
  line-height: 42px;
  text-align: center;
  font-size: 16px;
  border: 1px solid #D48058;
  color:#D48058;
}
.version_btn:hover {
  background: linear-gradient(105deg, #FEB38B 0%, #E38356 100%);
  color: #ffffff;
}

.questions {
    width: 1200px;
    margin: 0 auto;
}
.question_t {
    width: 144px;
    margin: 85px auto;
}
.question_t>span:nth-child(1) {
    display: inline-block;
    width: 150px;
    height: 6px;
    background: linear-gradient(138deg, #FFF6F2 0%, #FFC3A4 100%);
    // background: linear-gradient(138deg, #C1FAF7 0%, #33CDCE 100%);
}
.question_t>span:nth-child(2) {
    display: inline-block;
    left: 0;
    bottom: 4px;
    font-size: 36px;
    font-family: SourceHanSansCN-Bold, SourceHanSansCN;
    font-weight: bold;
    color: #27364E;
    line-height: 54px;
}

.question_img_box {
    width: 1200px;
    margin: 0 auto;
}

.question_img_ul {
    flex-wrap: wrap;
    justify-content: space-between;
}
.question_img_li {
    width: 45%;
    align-items: flex-start;
}
.question_img {
    width: 54px;
    height: 54px;
    margin-right: 27px;
}
.question_img_right>p {
    width: 140px;
    height: 45px;
    text-align: center;
    font-size: 22px;
    font-family: SourceHanSansCN-Bold, SourceHanSansCN;
    font-weight: bold;
    color: #202125;
    line-height: 50px;
    border-bottom: 2px solid #D48058;
}
.question_img_right>ul {
    border-top: 1px solid #E2EAF2;
    padding: 10px 0;
    box-sizing: border-box;
}
.question_img_right>ul>li {
    font-size: 14px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #6C6C6C;
    line-height: 22px;
}

.question_content>p:nth-child(1) {
    font-size: 20px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: bold;
    color: #202125;
    line-height: 30px;
    margin-top: 20px;
}
.question_content>p:nth-child(2) {
    font-size: 16px;
    font-family: SourceHanSansCN-Normal, SourceHanSansCN;
    font-weight: 400;
    color: #666666;
    line-height: 24px;
    margin-top: 12px;
}

.header_banner_text {
    width: 1200px;
    padding-top: 30px;
    box-sizing: border-box;
    left: 50%;
    transform: translate(-50%,0);
}
.header_banner_text>p {
    font-size: 24px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: bold;
    color: #125386;
    line-height: 36px;
    text-align: center;
}
.header_banner_text>p:nth-child(2) {
    font-size: 40px;
    margin: 16px 0 33px;
}
.header_banner_text>p:nth-last-child(1) {
    font-weight: 400 !important;
    color: #297EC9 !important;
}

.modules_view {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 11;
  > div {
    width: 420px;
    height: 350px;
    margin-top: 15%;
    margin-left: calc(50% - 300px);
    background: #fff;
    border-radius: 8px;
    text-align: center;
    > p:nth-child(1) {
      text-align: right;
      padding: 5px 20px;
      font-size: 30px;
      height: 30px;
    }
    > p:nth-child(2) {
      margin: 10px 0;
      font-size: 20px;
      margin-top: 0;
    }
    > img {
      margin: 20px auto;
      width: 200px;
      height: 200px;
    }
    >div {
      width: 200px;
      height: 44px;
      border-radius: 4px;
      border: 1px solid #C0C0C0;
      line-height: 44px;
      text-align: center;
      font-size: 16px;
      color: #838383;
      margin: 0 auto;
    }
  }
}
</style>