<template>
  <div>
    <div id='qrcode'></div>
  </div>
</template>

<script>

import { defineComponent, ref } from 'vue'
// 二维码
import QRCode from 'qrcodejs2-fix' // 引入qrcode
export default defineComponent({
  name: 'QRCode',
  props: {
    width: {
      type: Number,
      default() {
        return 180
      }
    },
    height: {
      type: Number,
      default() {
        return 180
      }
    },
    // 二维码地址
    url: {
      type: String,
      default() {
        return ''
      }
    }
  },
  setup() {
    function qrcode(url) {
      const codeHtml = document.getElementById('qrcode')
      codeHtml.innerHTML = ''
      if (url) {
        let qrcode = new QRCode('qrcode', {
          width: this.width,
          height: this.height,
          text: url,
          colorDark: '#000',
          colorLight: '#fff'
        })
      } else {
        let qrcode = new QRCode('qrcode', {
          width: this.width,
          height: this.height,
          text: this.url,
          colorDark: '#000',
          colorLight: '#fff'
        })
      }
    }
    return { // return中的数据会被父组件拿到
      qrcode
    }
  }
})

import { computed, onMounted, nextTick } from 'vue'
 
// const props = defineProps({
//   options: {
//     type: Object,
//     required: false
//   },
//   width: {
//       type: Number,
//       default() {
//         return 150
//       }
//     },
//     height: {
//       type: Number,
//       default() {
//         return 150
//       }
//     },
//     // 二维码地址
//     url: {
//       type: String,
//       default() {
//         return ''
//       }
//     }
// })
// onMounted(() => {
//   nextTick(() => {
//     autQRCode()
//   })
// })
// const autQRCode = () => {
//   let {
//     text = '二维码',
//     width = 200,
//     height = 200,
//     colorDark = '#333333',
//     colorLight = '#ffffff',
//     correctLevel = QRCode.CorrectLevel.L
//   } = props.options
//   const qrcode = new QRCode('qrcode', {
//     text,
//     width,
//     height,
//     colorDark, //二维码颜色
//     colorLight, //二维码背景色
//     correctLevel //容错率，L/M/H})
//   })
//   console.log(qrcode, 'qrcode')
//   return qrcode.value
// }
</script>

<style scoped>
#qrcode {
  display: inline-block;
}

#qrcode img {
  width: 132px;
  height: 132px;
  background-color: #fff;
  padding: 6px;
}
</style>