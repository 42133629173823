<template>
  <section id="header_banner">
    <div class="header_banner relative">
      <img src="../../assets/img/buy_bg.png" alt="" />
    </div>
  </section>
  <section id="version">
    <div class="version relative">
      <ul class="flex version_ul absolute">
        <li v-for="(item, index) in state.versionList" :key="index">
          <p class="version_t">{{ item.version }}</p>
          <p class="version_t2">{{ item.tit }}</p>
          <ul class="version_ul2">
            <li
              class="version_li"
              v-for="(item2, index2) in item.ulList"
              :key="index2"
            >
              {{ item2 }}
            </li>
          </ul>
          <div class="version_btn" @click="clickBtn(item)">{{ item.btn }}</div>
        </li>
      </ul>
    </div>
  </section>
  <section id="businesscard">
    <div class="businesscard_tit">提供优质专业服务，为您保驾护航！</div>
    <businesscard></businesscard>
  </section>
  <!-- 企业微信二维码 -->
  <div class="code">
    <transition name="plus-icon">
      <div class="fixed-left code-left" v-if="state.show2">
        <p>给您提供更专业的服务，请添加客服企业微信</p>
        <div class="code-img">
          <img class="code-img1" src="../../assets/img/fixed-img4.png" alt="" />
          <img class="code-img2" src="../../assets/img/fixed-img5.png" alt="" />
        </div>
      </div>
    </transition>
  </div>
  <!-- 小程序码 -->
  <div class="program">
    <transition name="plus-icon">
      <div class="fixed-left program-left" v-if="state.show3">
        <p style="cursor: pointer" @click="state.show3 = false">×</p>
        <p>请扫描小程序二维码进行体验</p>
        <img
          src="../../assets/img/fixed-img6.jpg"
          width="260"
          height="260"
          alt=""
        />
      </div>
    </transition>
  </div>
</template>

<script setup lang="ts">
import { reactive, watchEffect } from "vue";
import Bus from "/@/utils/bus";
import getAssetsFile from "/@/assets/img/image_js/image_js";
import businesscard from "./businesscard.vue";

const state = reactive({
  versionList: [
    {
      version: "票靓·微报销",
      tit: "适合个人、小微企业、个体工商户",
      ulList: [
        "无需购买企业年费套餐",
        "全面免费开放报销审批功能",
        "票仓扩容包即买即用",
      ],
      btn: "购买个人扩容包",
    },
    {
      version: "票靓·专业版",
      tit: "适合对财税管理有更多专业需求的企业用户",
      ulList: [
        "涵盖企业开票、认证、财务管理",
        "支持全电发票涉税业务流程",
        "2000张票仓容量起售",
      ],
      btn: "购买企业套餐",
    },
    {
      version: "私有部署",
      tit: "支持私有云/公有云/混合云部署",
      ulList: [
        "支持企业系统集成",
        "可专属定制业务功能",
        "丰富的数据接口、数据私有化方案",
      ],
      btn: "联系客服",
    },
  ],
  show2:false,
  show3:false
});

function showFn5() {
  state.show2 = !state.show2
  // hide2.value = true
}
function clickBtn(item) {
    console.log(item);
    if(item.btn=='购买企业套餐') {
        Bus.emit('goRouter', 'Thali')
    } else if(item.btn=='购买个人扩容包') {
        Bus.emit('goRouter', 'Dilatation')
    } else {
      window.open('http://seapower.mikecrm.com/udpwVLB', "_blank")
    }
    
}
</script>

<style scoped>
div,
li,
p,
span {
  cursor: context-menu;
}
.flex {
  display: flex;
}
.relative {
  position: relative;
}
.relative > img {
  width: 100%;
}
.relative > div {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.absolute {
  position: absolute;
}

.header_banner>img {
  width: 1920px;
  height: 460px;
  margin: 0 auto;
}

#businesscard {
  padding-bottom: 75px;
  padding-top: 347px;
  background: #f9fdff;
}
.version {
  height: 20px;
  /* background: #f9fdff; */
}
.version_ul {
  justify-content: space-between;
  width: 1200px;
  top: -37px;
  left: 50%;
  transform: translate(-50%, 0);
}
.version_ul > li {
  /* width: 225px;
  height: 201px; */
  width: 372px;
  height: 321px;
  background: #FFFFFF;
  box-shadow: 0px 6px 40px 0px rgba(194,203,207,0.1);
  padding: 25px 35px;
  box-sizing: border-box;
}
.version_ul > li > p {
  text-align: center;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
}
.version_t {
  font-size: 24px;
  font-weight: bold;
  color: #27364e;
  line-height: 36px;
  margin-top: 5px;
}
.version_t2 {
  font-size: 14px;
  font-weight: 400;
  color: #9c9c9c;
  line-height: 21px;
  margin: 18px 0;
}
.version_ul2 {
  border-top: 1px dashed #d9e4e4;
  padding-top: 10px;
}
.version_li {
  margin-bottom: 7px;
  color: #27364e;
  font-size: 16px;
  margin-left: 14px;
}
.version_li::before {
  /* position: absolute;
  left: 10px; */
  font-size: 6px;
  content: "●";
  color: #33cdce;
  margin-left: -13px;
  margin-right: 6px;
}
.version_btn {
  width: 240px;
  height: 48px;
  color: #33cdce;
  border: 1px solid #33cdce;
  border-radius: 3px;
  margin: 0 auto;
  margin-top: 30px;
  line-height: 48px;
  text-align: center;
  font-size: 16px;
}
.version_btn:hover {
  background: #33cdce;
  color: #ffffff;
}

.businesscard_tit {
  margin-bottom: 44px;
  font-size: 28px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 40px;
  text-align: center;
}

.code {
  display: flex;
  align-items: center;
  /*margin-bottom: 15px;*/
  /*height: 169px;*/
}
.program {
  display: flex;
  align-items: center;
  /*height: 169px;*/
}
.fixed-left {
  padding: 16px;
  width: 460px;
  background: #ffffff;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.3),
    0px 4px 10px 0px rgba(0, 0, 0, 0.3), 0px 4px 10px 0px rgba(0, 0, 0, 0.3);
}
.message-left {
  position: fixed;
  /* bottom: 373px;
  right: 104px; */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100px;
}
.code-left {
  position: fixed;
  /* bottom: 193px;
  right: 104px; */
  
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /*height: 169px;*/
}
.program-left {
  position: fixed;
  /* bottom: 14px;
  right: 104px; */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /*height: 169px;*/
}
.fixed-left p {
  /* margin-left: 57px; */
  font-size: 26px;
  text-align: center;
  height: 40px;
  line-height: 40px;
  font-family: SourceHanSansCN-Regular;
  color: #797979;
}
.message-left p:nth-child(1) {
  margin-top: 20px;
  font-size: 14px;
}
.message-left p:nth-child(2) {
  /* margin-top: 20px; */
  font-size: 18px;
  margin-left: 100px;
}
.code-left p {
  font-size: 14px;
  margin-left: 31px;
  margin-top: 4px;
}
.code-left .code-img {
  display: flex;
}
.code-left .code-img1 {
  /* display: flex;
  justify-content: center;
  align-items: center; */
  width: 120px;
  height: 120px;
  margin-left: 45px;
  /* background-image: url(../../assets/img/fixed-img4.png); */
}
.code-left .code-img2 {
  width: 120px;
  height: 120px;
  margin-left: 10px;
}
.program-left p {
  font-size: 22px;
  color: #333;
}
.program-left img {
  /* margin-left: 105px; */
  margin: 0 auto;
}
.fixed-right {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 61px;
  height: 61px;
  border-radius: 50%;
  background: #07b9b9;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.16);
}
.fixed-right:hover {
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.3),
    1px 4px 10px 0px rgba(0, 0, 0, 0.3), 0px 4px 10px 0px rgba(0, 0, 0, 0.3);
}
.message-right {
  position: fixed;
  bottom: 392px;
  right: 37px;
}
.code-right {
  position: fixed;
  bottom: 249px;
  right: 37px;
}
.program-right {
  position: fixed;
  bottom: 70px;
  right: 37px;
}
.program-right img {
  width: 25px;
}
.boult1 {
  position: fixed;
  right: 5px;
  bottom: 410px;
}
.boult2 {
  position: fixed;
  right: 5px;
  bottom: 261px;
}
.boult3 {
  position: fixed;
  right: 5px;
  bottom: 81px;
}
.plus-icon-enter-active {
  transition: opacity 0.7s;
}
.plus-icon-enter-from {
  opacity: 0;
}

.plus-icon-leave-active {
  transition: opacity 0.7s;
}
.plus-icon-leave-to {
  opacity: 0;
}
.program-left> p:nth-child(1) {
      text-align: right;
      font-size: 30px;
      height: 30px;
      text-align: right;
      margin-top: -10px;
    }
</style>