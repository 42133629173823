<template>
  <header style="position: fixed;top:0;left:0;right:0;height: 60px; background: #FFFFFF;z-index: 10;
box-shadow: 0px 0px 30px 0px rgba(144,164,179,0.16);">
      <div
      style="width: 1200px; height: 60px; background: #FFFFFF;display: flex;justify-content: space-between;margin:0 auto;">
        <div
        style="display: flex;width: 232px; height: 60px;font-weight: 500;font-family: PingFang SC-Medium; margin-left: 0px; font-size: 25px;align-items: center;">
<!--      票靓微报销-->
      <img  src="../../assets/img/logo-new.png" alt="" style="height: 35px;"  @click="goRouter('/')"/>
    </div>
    <div style="display: flex;height: 60px;">
      <div :class="[state.routerPath==='/'?'header-button--active':'','header-button']"
           @click="goRouter('/')">首页
      </div>
      <div :class="[state.routerPath==='Scene'?'header-button--active':'','header-button']"
           @click="goRouter('Scene')">解决场景
      </div>
      <div :class="[(state.routerPath==='Buy'||state.routerPath==='Thali'||state.routerPath==='Dilatation')?'header-button--active':'','header-button']" @mouseenter="state.show=true" @mouseleave="state.show=false">
          <span @click="goRouter('Buy')" >购买</span>
           <div class="buy" v-if="state.show">
             <p @click="goRouter('Thali')" :class="[state.routerPath==='Thali'?'header-button--active':'','header-button']">企业套餐</p>
             <p @click="goRouter('Dilatation')" :class="[state.routerPath==='Dilatation'?'header-button--active':'','header-button']">个人扩容包</p>
           </div>
      </div>
      <div :class="[state.routerPath==='AboutUs'?'header-button--active':'','header-button']"
           @click="goRouter('AboutUs')">关于我们
      </div>
      <!-- <div :class="[state.routerPath==='ConcatUs'?'header-button--active':'','header-button']"
           @click="goRouter('ConcatUs')">联系我们
      </div> -->
      <div class="header-button" @click="help">帮助中心</div>
      <!-- <div class="header-button" @click="login">登录</div> -->
    </div>
    <div style="display:flex;align-items:center">
      <div><img src="../../assets/img/phone.png" alt="" style="width:18px;height:18px;"></div>
      <p style="font-size: 20px;font-family: Gotham-MediumItalic, Gotham;font-weight: normal;color: #202125;line-height: 15px;margin-left:5px">400-059-6663</p>
      <div style="width: 75px;height: 28px;border-radius: 1px;border: 1px solid #333333;text-align:center;line-height:28px;margin-left:25px;font-size:14px;cursor: pointer;" @click="login">登录</div>
    </div>
      </div>
    
  </header>
</template>

<script setup lang="ts">
import {useRouter} from "vue-router";
import {onBeforeUnmount, reactive, watchEffect} from "vue";
import Bus from "/@/utils/bus";
import {ROUTER_CACHE_KEY} from "/@/enums/cacheEnum";

const state = reactive({
  routerPath: sessionStorage.getItem(ROUTER_CACHE_KEY),
  show:false
})

const router = useRouter()

//刷新页面的时候，重新设置下刷新之前的路由
if (sessionStorage.getItem(ROUTER_CACHE_KEY)) {
  state.routerPath = sessionStorage.getItem(ROUTER_CACHE_KEY) || ''
  router.push(sessionStorage.getItem(ROUTER_CACHE_KEY) || '')
}

function goRouter(r) {
  sessionStorage.setItem(ROUTER_CACHE_KEY, r)
  state.routerPath = r
  console.log(r)
  router.push(r)
}

function login() {
  window.open('https://baoxiao666.com/pl', "_blank")
}
function help() {
  window.open('https://baoxiao666.com/doc/help/', "_blank")
}

Bus.on('goRouter', (msg) => {
  goRouter(msg)
})
onBeforeUnmount(() => {
  Bus.off('goRouter')
})
watchEffect(() => {
  console.log(sessionStorage.getItem(ROUTER_CACHE_KEY));
  // console.log(state.body5Index);
});
</script>

<style scoped>
.header-button {
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 80px; */
  padding: 0 25px;
  height: 60px;
  font-size: 18px;
}

.header-button:hover {
  color: #41C7C0;
}

.header-button--active {
  /* background: #41C7C0; */
  color: #41C7C0 !important;
}

.header-button--active:hover {
  /* background: #41C7C0; */
  color: #41C7C0;
}
.buy {
  position: absolute;
  top: 47px;
  width: 92px;
  height: 98px;
  background: #FFFFFF;
  z-index: 2;
  border-radius: 3px;
  box-shadow: 0px 1px 10px 0px rgba(160,216,245,0.36);
}
.buy>p {
  width: 100%;
  height: 48px;
  font-size: 14px;
  font-family: SourceHanSansCN-Normal, SourceHanSansCN;
  font-weight: 400;
  color: #333333;
  line-height: 50px;
  padding: 0 9px;
  box-sizing: border-box;
}
</style>
