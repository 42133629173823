<template>
  <div class="top_banner">
    <img src="../../assets/img/price_top_banner.png" alt="" srcset="" />
    <div class="top_text">
      <div class="top_title">
        <p>企业版价格套餐</p>
        <p>请根据您的企业规模和需求，选择不同的产品套餐</p>
      </div>
      <div class="top_textL">
        <p>小微企业套餐限时特惠中</p>
        <ul>
          <li>5人内限时免费</li>
          <li>6-10人特惠价¥800/年</li>
          <li>11-15人立减¥1600/年</li>
        </ul>
      </div>
    </div>
  </div>
  <div class="price_list">
    <div class="list_left">
      <div class="banner_left_btn banner_btn" @click="leftBtn" style="left:90px">
        <img src="../../assets/img/left2.png" alt="">
      </div>
    </div>
    <div
      style="width: 100%; height: 100%; overflow-x: scroll"
      id="scroll_bar"
      class="scroll_bar"
    >
      <div class="list_content">
        <div v-if="mouseShow.scrollStart">
          <div
            :class="[
              packageQueryResult.data.packagelist.length - 1 ==
              mouseShow.listActive
                ? 'activeList'
                : 'list',
            ]"
          >
            <div class="list_top">
              <p>
                {{
                  packageQueryResult.data.packagelist[
                    packageQueryResult.data.packagelist.length - 1
                  ].packageName
                }}
              </p>
              <div>
                <p>
                  <span>￥</span
                  ><span
                    :style="{
                      'font-size':
                        packageQueryResult.data.packagelist[
                          packageQueryResult.data.packagelist.length - 1
                        ].packageFinalBasePrice > 5
                          ? '40px'
                          : '53px',
                    }"
                    >{{
                      packageQueryResult.data.packagelist[
                        packageQueryResult.data.packagelist.length - 1
                      ].packageFinalBasePrice
                    }}</span
                  >
                </p>
                <p><span>/年起</span></p>
              </div>
              <p>
                ￥{{
                  packageQueryResult.data.packagelist[
                    packageQueryResult.data.packagelist.length - 1
                  ].packagePrice
                }}
              </p>
            </div>
            <div class="list_line"></div>
            <div class="list_message">
              <div class="list_enjoy">
                <div class="enjoy_left"></div>
                <p>可享以下权益</p>
                <div class="enjoy_right"></div>
              </div>
              <ul>
                <li
                  v-for="(item2, index2) in packageQueryResult.data.packagelist[
                    packageQueryResult.data.packagelist.length - 1
                  ].gainPrivilege"
                  :key="index2"
                >
                  <p
                    :style="{
                      color: item2.subtitleName == '不支持' ? '#999' : '',
                    }"
                  >
                    {{ item2.typeName }}
                  </p>
                  <p>
                    {{
                      item2.subtitleName == "支持"
                        ? "√"
                        : item2.subtitleName == "不支持"
                        ? "×"
                        : item2.subtitleName
                    }}
                  </p>
                </li>
              </ul>
            </div>
            <div
              class="list_button"
              @click="
                (mouseShow.mouseShow = true)
              "
            >
              购买套餐
            </div>
          </div>
        </div>
        <div
          v-for="(item, index) in packageQueryResult.data.packagelist"
          :key="item.packageId"
          :class="[index == mouseShow.listActive ? 'activeList' : 'list']"
          @click="mouseShow.listActive = index"
        >
          <div class="list_top">
            <p>{{ item.packageName }}</p>
            <div>
              <p>
                <span>￥</span
                ><span
                  :style="{
                    'font-size':
                      item.packageFinalBasePrice > 5 ? '40px' : '53px',
                  }"
                  >{{ item.packageFinalBasePrice }}</span
                >
              </p>
              <p><span>/年起</span></p>
            </div>
            <p>￥{{ item.packagePrice }}</p>
          </div>
          <div class="list_line"></div>
          <div class="list_message">
            <div class="list_enjoy">
              <div class="enjoy_left"></div>
              <p>可享以下权益</p>
              <div class="enjoy_right"></div>
            </div>
            <ul>
              <li v-for="(item2, index2) in item.gainPrivilege" :key="index2">
                <p
                  :style="{
                    color: item2.subtitleName == '不支持' ? '#999' : '',
                  }"
                >
                  {{ item2.typeName }}
                </p>
                <p>
                  {{
                    item2.subtitleName == "支持"
                      ? "√"
                      : item2.subtitleName == "不支持"
                      ? "×"
                      : item2.subtitleName
                  }}
                </p>
              </li>
            </ul>
          </div>
          <div
            class="list_button"
            @click="
              (mouseShow.mouseShow = true), (mouseShow.listActive = index)
            "
          >
            购买套餐
          </div>
        </div>
        <div v-if="mouseShow.scrollStart">
          <div :class="[0 == mouseShow.listActive ? 'activeList' : 'list']">
            <div class="list_top">
              <p>{{ packageQueryResult.data.packagelist[0].packageName }}</p>
              <div>
                <p>
                  <span>￥</span
                  ><span
                    :style="{
                      'font-size':
                        packageQueryResult.data.packagelist[0]
                          .packageFinalBasePrice > 5
                          ? '40px'
                          : '53px',
                    }"
                    >{{
                      packageQueryResult.data.packagelist[0]
                        .packageFinalBasePrice
                    }}</span
                  >
                </p>
                <p><span>/年起</span></p>
              </div>
              <p>
                ￥{{
                  packageQueryResult.data.packagelist[0]
                    .packagePrice
                }}
              </p>
            </div>
            <div class="list_line"></div>
            <div class="list_message">
              <div class="list_enjoy">
                <div class="enjoy_left"></div>
                <p>可享以下权益</p>
                <div class="enjoy_right"></div>
              </div>
              <ul>
                <li
                  v-for="(item2, index2) in packageQueryResult.data
                    .packagelist[0].gainPrivilege"
                  :key="index2"
                >
                  <p
                    :style="{
                      color: item2.subtitleName == '不支持' ? '#999' : '',
                    }"
                  >
                    {{ item2.typeName }}
                  </p>
                  <p>
                    {{
                      item2.subtitleName == "支持"
                        ? "√"
                        : item2.subtitleName == "不支持"
                        ? "×"
                        : item2.subtitleName
                    }}
                  </p>
                </li>
              </ul>
            </div>
            <div
              class="list_button"
              @click="
                (mouseShow.mouseShow = true)
              "
            >
              购买套餐
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="list_right">
      <div class="banner_left_btn banner_btn" @click="rightBtn" style="right:90px;">
        <img src="../../assets/img/right2.png" style="margin-left:8px;" alt="">
      </div>
    </div>
  </div>
  <div class="question">
    <div class="question_title">
      <div class="question_left"></div>
      <p>购买问题</p>
      <div class="question_right"></div>
    </div>
    <div class="question_list">
      <div>
        <div class="question_list_title">
          <img src="../../assets/img/payment.png" alt="" srcset="" />
          <p style="color: #f8b713">付款方式</p>
        </div>
        <ul>
          <li>微信支付：订单有效期内在线通过微信支付即可完成</li>
          <li>
            对公付款：若您采用对公付款的方式，请添加我们的客服企业微信，并于订单有效期内完成对公支付，在对公付款时备注您的订单编号。将订单编号及对公支付的信息发给客服，我们将为您处理。
          </li>
        </ul>
      </div>
      <div>
        <div class="question_list_title">
          <img src="../../assets/img/invoice.png" alt="" srcset="" />
          <p style="color: #9187ff">是否提供发票</p>
        </div>
        <ul>
          <li>票靓提供增值税电子普通发票和增值税专用发票。</li>
          <li>如您需要获取增值税专用发票，请联系客服。</li>
          <li>
            如您需要获取增值税电子普通发票，请在确认订单时选择开具发票，或在订单完成后，在个人中心-发票服务功能开具发票。
          </li>
        </ul>
      </div>
      <div>
        <div class="question_list_title">
          <img src="../../assets/img/contract.png" alt="" srcset="" />
          <p style="color: #41c7c0">购买和使用权益套餐</p>
        </div>
        <ul>
          <li>
            票靓的企业版VIP套餐购买需要先创建企业平台账号，票靓提供企业平台账号级别的统一企业管理功能。
          </li>
          <li>
            为企业平台账号购买套餐成功后，可直接进行使用，无需安装，即可实现随时移动报销。
          </li>
        </ul>
      </div>
      <div>
        <div class="question_list_title">
          <img src="../../assets/img/programme.png" alt="" srcset="" />
          <p style="color: #5484ff">后续如何调整方案和合同</p>
        </div>
        <ul>
          <li>
            购买套餐后，在套餐权益有效期后如需调整方案，购买最新权益套餐即可。
          </li>
          <li>在套餐权益有效内，如需调整票仓容量，可购买扩容包。</li>
          <li>在套餐权益有效内，如需调整企业数量及员工人数，请联系客服。</li>
          <li>在套餐权益有效内，如需调整增值服务，请联系客服。</li>
        </ul>
      </div>
    </div>
  </div>
  <div class="bottom_banner">
    <img src="../../assets/img/price_advertisement.png" alt="" srcset="" />
  </div>
  <div class="modules_view" v-if="mouseShow.mouseShow">
    <div>
      <p style="cursor: pointer" @click="mouseShow.mouseShow = false">×</p>
      <p>请您扫描下面的二维码进入小程序端购买套餐。</p>
      <img src="../../assets/img/fixed-img6.jpg" alt="" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { reactive } from "vue";
import axios from "/@/utils/request";

let packageQueryResult: any = reactive({ data: {} });
let mouseShow = reactive({ mouseShow: false, listActive: 0,scrollStart:false,scrollLeft:0 });
axios
  .get("/order/orderPackage/packageQuery")
  .then((res) => {
    packageQueryResult.data = res.result;
  })
  .catch((err) => {
    console.log(err);
  });

document.onscroll = function (e: Event) {
  mouseShow.scrollStart = true
};
function leftBtn(e:any) {
  if(mouseShow.listActive!=0) {
    if(mouseShow.listActive==1) {
      e.target.offsetParent.offsetParent.offsetParent.childNodes[1].scrollLeft = 17
    } else {
      e.target.offsetParent.offsetParent.offsetParent.childNodes[1].scrollLeft = 180*mouseShow.listActive
    }
    mouseShow.listActive--
  } else {
    mouseShow.listActive=packageQueryResult.data.packagelist.length-1
    e.target.offsetParent.offsetParent.offsetParent.childNodes[1].scrollLeft = 180*mouseShow.listActive
  }
}

function rightBtn(e:any) {
  if(mouseShow.listActive!=packageQueryResult.data.packagelist.length-1) {
    if(mouseShow.listActive==1) {
      e.target.offsetParent.offsetParent.offsetParent.childNodes[1].scrollLeft = 17
    } else {
      e.target.offsetParent.offsetParent.offsetParent.childNodes[1].scrollLeft = 250*mouseShow.listActive
    }
    mouseShow.listActive++
  } else {
    mouseShow.listActive=0
    e.target.offsetParent.offsetParent.offsetParent.childNodes[1].scrollLeft = 17
  }
}
</script>

<style lang="less" scoped>
div,
p {
  font-family: PingFang SC-Regular, PingFang SC;
  box-sizing: border-box;
}
// .scroll_bar::-webkit-scrollbar { width: 1px !important }
// .scroll_bar { -ms-overflow-style: none; }
// .scroll_bar  { overflow: -moz-scrollbars-none; }
.top_banner {
  position: relative;
  width: 100%;
  height: 635px;
  margin-bottom: 33px;
  // overflow: hidden;
  background: linear-gradient(180deg, #41c7c0 0%, #58ded7 100%);
  > img {
    position: absolute;
    width: 1233px;
    height: 667px;
    // top: -33px;
    // left: -17px;
  }
  .top_text {
    position: absolute;
    top: 189px;
    left: 194px;
    color: #fff;
    .top_title {
      text-align: center;
      > p:nth-child(1) {
        font-size: 49px;
        font-weight: 500;
        color: #ffffff;
        line-height: 58px;
      }
      > p:nth-last-child(1) {
        font-size: 18px;
        font-weight: 400;
        line-height: 21px;
        opacity: 0.5;
        margin-top: 22px;
        margin-bottom: 53px;
      }
    }
    .top_textL {
      > p {
        height: 51px;
        font-size: 27px;
        font-weight: 500;
        color: #ffffff;
        line-height: 40px;
      }
      ul {
        margin-left: 20px;
        margin-top: 3px;
        li {
          height: 43px;
          font-size: 20px;
          font-weight: 400;
          color: #ffffff;
          line-height: 23px;
          text-decoration: underline;
          opacity: 0.75;
          list-style: disc;
        }
      }
    }
  }
}
.price_list {
  height: 980px;
  width: 100%;
  position: relative;
  .list_left {
    position: absolute;
    top: 0;
    left: 0;
    width: 265px;
    height: 100%;
    z-index: 10;
    background: linear-gradient(
      90deg,
      #ffffff 0%,
      rgba(255, 255, 255, 0.2) 100%
    );
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
  }
  .banner_btn {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: #000;
    opacity: 0.3;
    text-align: center;
    line-height: 50px;
    position: absolute;
    top: 50%;
    >img {
      width: 40px;
      height: 40px;
      margin-top: 6px;
      margin-left: 3px;
    }
  }
  .banner_btn:hover {
    opacity: 0.5;
  }
  .list_right {
    position: absolute;
    top: 0;
    right: 0;
    width: 265px;
    height: 100%;
    // z-index: 10;
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0.2) 0%,
      #ffffff 100%
    );
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
  }
  .list_content {
    width: max-content;
    display: flex;
    height: 100%;
    overflow: auto;
    // padding: 0 265px;
    .activeList {
      width: 252px;
      height: 933px;
      box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.11);
      border-radius: 7px 7px 7px 7px;
      margin-left: 20px;
      opacity: 1;
      border: 2px solid #41c7c0;
      position: relative;
      margin-top: 16px;
    }
    .list {
      margin-left: 20px;
      width: 235px;
      height: 872px;
      margin-top: 40px;
      background: #ffffff;
      box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.09);
      border-radius: 7px 7px 7px 7px;
      opacity: 0.85;
      position: relative;
    }
    > div {
      .list_top {
        padding: 0 9px;
        text-align: center;
        padding-top: 35px;
        height: 215px;
        > p:nth-child(1) {
          height: 64px;
          font-size: 37px;
          font-weight: 500;
          color: #222222;
          line-height: 43px;
        }
        > div {
          display: flex;
          align-items: flex-end;
          justify-content: center;
          > p {
            color: #41c7c0;
            font-size: 20px;
            font-weight: bold;
          }
          > p:nth-last-child(1) {
            color: #222222;
            font-size: 20px;
            line-height: 55px;
          }
        }
        > p:nth-last-child(1) {
          height: 33px;
          font-size: 20px;
          font-weight: 500;
          color: #222222;
          line-height: 23px;
          text-decoration: line-through;
          opacity: 0.67;
        }
      }
      .list_line {
        height: 3px;
        width: calc(100% - 16px);
        border-top: 1px dashed #00000063;
        border-bottom: 1px dashed #00000063;
        margin: 0 8px;
        margin-top: 23px;
      }
      .list_message {
        .list_enjoy {
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 33px 0;
          .enjoy_left {
            width: 42px;
            height: 4px;
            background: linear-gradient(
              270deg,
              #4ed6cf 0%,
              rgba(78, 215, 208, 0) 100%
            );
            opacity: 1;
          }
          > p {
            width: 120px;
            height: 28px;
            font-size: 20px;
            font-weight: 500;
            color: #999999;
            line-height: 23px;
          }
          .enjoy_right {
            width: 42px;
            height: 4px;
            background: linear-gradient(
              270deg,
              rgba(78, 215, 208, 0) 0%,
              #50d9d2 100%
            );
            opacity: 1;
          }
        }
        ul {
          height: 520px;
          overflow: auto;
          li {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 17px;
            margin-bottom: 27px;
            > p:nth-child(1) {
              height: 33px;
              font-size: 18px;
              font-weight: 400;
              color: #222222;
              line-height: 21px;
            }
            > p:nth-last-child(1) {
              height: 33px;
              font-size: 18px;
              font-weight: 400;
              color: #999999;
              line-height: 21px;
            }
          }
        }
      }
      .list_button {
        width: 195px;
        height: 33px;
        opacity: 1;
        border: 1px solid #41c7c0;
        color: #41c7c0;
        font-size: 13px;
        line-height: 33px;
        font-weight: bold;
        text-align: center;
        border-radius: 4px;
        margin: 0 auto;
        position: absolute;
        bottom: 37px;
        left: 20px;
      }
      .list_button:hover {
        background: #41c7c0;
        color: #fff;
      }
    }
  }
}
.question {
  .question_title {
    display: flex;
    align-items: center;
    justify-content: center;
    .question_left {
      width: 42px;
      height: 4px;
      background: linear-gradient(
        270deg,
        #f8b713 0%,
        rgba(248, 183, 19, 0) 100%
      );
      opacity: 1;
    }
    > p {
      width: 80px;
      height: 28px;
      font-size: 20px;
      font-weight: 500;
      color: #222222;
      line-height: 23px;
      margin: 0 23px;
    }
    .question_right {
      width: 42px;
      height: 4px;
      background: linear-gradient(
        270deg,
        rgba(248, 183, 19, 0) 0%,
        #f8b713 100%
      );
      opacity: 1;
    }
  }
  .question_list {
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    margin-top: 21px;
    > div {
      width: 203px;
      .question_list_title {
        display: flex;
        align-items: center;
        margin-bottom: 13px;
        > img {
          width: 27px;
          height: 27px;
        }
        > p {
          margin-left: 13px;
          font-size: 13px;
          font-weight: 500;
          line-height: 16px;
        }
      }
      ul {
        li {
          list-style: disc;
          width: 203px;
          // height: 68px;
          font-size: 13px;
          font-weight: 500;
          color: #a2a4a6;
          line-height: 16px;
          margin-left: 20px;
        }
      }
    }
  }
}
.bottom_banner {
  margin-top: 56px;
  margin-bottom: 37px;
  width: 100%;
  img {
    width: 1000px;
    height: 257px;
    margin: 0 auto;
  }
}
.modules_view {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 11;
  > div {
    width: 600px;
    height: 380px;
    margin-top: 15%;
    margin-left: calc(50% - 300px);
    background: #fff;
    border-radius: 8px;
    text-align: center;
    > p:nth-child(1) {
      text-align: right;
      padding: 5px 20px;
      font-size: 30px;
    }
    > p:nth-child(2) {
      margin: 15px 0;
      font-size: 20px;
      margin-top: 0;
    }
    > img {
      margin: 0 auto;
      width: 260px;
    }
  }
}
</style>
