
// export default imagesMap

const getAssetsFile = (url) => {
    console.log(url);
    // return new URL(`../${url}`, import.meta.url).href;
    return '/img'+url;
};

export default {
    getAssetsFile,
}
