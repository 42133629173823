<template>
  <footer>
    <div
      style="
        width: 100%;
        height: 301px;
        min-width: max-content;
        background-color: #0b273f;
        padding-left: 100px;
        padding-top: 40px;
        padding-right: 100px;
        position: relative;
        box-sizing:border-box;
      "
    >
      <!--      <div style="display: flex; flex-direction: column; width: 110px">-->
      <!--        <div-->
      <!--          style="-->
      <!--            display: flex;-->
      <!--            color: #222222;-->
      <!--            font-weight: 400;-->
      <!--            font-size: 13px;-->
      <!--          "-->
      <!--        >-->
      <!--          产品概况-->
      <!--        </div>-->
      <!--        <a-->
      <!--          style="-->
      <!--            display: flex;-->
      <!--            color: #798195;-->
      <!--            font-weight: 400;-->
      <!--            font-size: 11px;-->
      <!--            margin-top: 15px;-->
      <!--          "-->
      <!--          @click="goRouter('/')"-->
      <!--          >产品介绍-->
      <!--        </a>-->
      <!--        <a-->
      <!--          style="-->
      <!--            display: flex;-->
      <!--            color: #798195;-->
      <!--            font-weight: 400;-->
      <!--            font-size: 11px;-->
      <!--            margin-top: 5px;-->
      <!--          "-->
      <!--          @click="goRouter('Price')"-->
      <!--          >价格-->
      <!--        </a>-->
      <!--        <a-->
      <!--          style="-->
      <!--            display: flex;-->
      <!--            color: #798195;-->
      <!--            font-weight: 400;-->
      <!--            font-size: 11px;-->
      <!--            margin-top: 5px;-->
      <!--          "-->
      <!--          @click="goRouter('Download')"-->
      <!--          >下载-->
      <!--        </a>-->
      <!--      </div>-->
      <div style="
        display: flex;
        width: 1200px;
        margin:0 auto
      ">
        <div style="display: flex; flex-direction: column; width: 540px">
        <div
          style="
            display: flex;
            color: #222222;
            font-weight: 400;
            font-size: 14px;
          "
        >
          <img src="../../assets/img/logo2.png" alt="" class="logo_white" />
        </div>
        <div
          style="
            display: flex;
            color: #798195;
            font-weight: 400;
            font-size: 14px;
            margin-top: 15px;
            align-items:flex-end
          "
        >
          客服电话：<span style="color: #ffff;font-size:20px;opacity: 0.8;">400-059-6663</span>
        </div>
        <div
          style="
            display: flex;
            color: #798195;
            font-weight: 400;
            font-size: 14px;
            margin-top: 5px;
          "
        >
          电子邮箱：<span style="color: #ffff;opacity: 0.8;">haiwen_pl@ccitc.net.cn</span>
        </div>
        <div
          style="
            display: flex;
            color: #798195;
            font-weight: 400;
            font-size: 14px;
            margin-top: 5px;
          "
        ></div>
      </div>
      <div style="display: flex; flex-direction: column; width: 180px">
        <div
          style="
            display: flex;
            color: #fff;
            font-weight: 400;
            font-size: 14px;
            opacity: 0.6;
          "
        >
          票靓产品
        </div>
        <a
          style="
            display: flex;
            color: #fff;
            font-weight: 400;
            font-size: 14px;
            margin-top: 15px;;opacity: 0.8;
          "
          @click="goRouter('Buy')"
          target="_blank"
          >我要购买</a
        >
        <a
          style="
            display: flex;
            color: #fff;
            font-weight: 400;
            font-size: 14px;
            margin-top: 5px;;opacity: 0.8;
          "
          @click="goRouter('Scene')"
          target="_blank"
          >解决场景</a
        >
        <a
          style="
            display: flex;
            color: #fff;
            font-weight: 400;
            font-size: 14px;
            margin-top: 5px;;opacity: 0.8;
          "
          @click="help"
          target="_blank"
          >帮助中心</a
        >
      </div>
      <div style="display: flex; flex-direction: column; width: 336px">
        <div
          style="
            display: flex;
            color: #fff;
            opacity: 0.6;
            font-weight: 400;
            font-size: 14px;
          "
        >
          友情链接
        </div>
        <a
          style="
            display: flex;
            color: #fff;
            font-weight: 400;
            font-size: 14px;
            margin-top: 15px;;opacity: 0.8;
          "
          href="http://www.powertax.com.cn/"
          target="_blank"
          >海闻科技</a
        >
        <a
          style="
            display: flex;
            color: #fff;
            font-weight: 400;
            font-size: 14px;
            margin-top: 5px;;opacity: 0.8;
          "
          href="http://www.upitc.com.cn/ccitc/"
          target="_blank"
          >海华税务</a
        >
        <a
          style="
            display: flex;
            color: #fff;
            font-weight: 400;
            font-size: 14px;
            margin-top: 5px;opacity: 0.8;
          "
          href="https://www.quickf.cn/protal/index.html"
          target="_blank"
          >票靓在线大会计</a
        >
      </div>
      <div style="text-align: center; font-size: 14px; color: #a2a4a6">
        <img
          src="../../assets/img/wxchartcode.png"
          style="width: 100px; height: 100px"
          alt="票靓小程序"
        />
        <div style="width: 100px">扫码体验</div>
        <!-- <div>票靓微报销</div> -->
      </div>
      <div
        style="
          margin-left: 20px;
          text-align: center;
          font-size: 14px;
          color: #a2a4a6;
        "
      >
        <img
          src="../../assets/img/plpublic.png"
          style="width: 100px; height: 100px"
          alt="票靓公众号"
        />
        <div style="width: 100px">关注公众号</div>
        <!-- <div>票靓微报销</div> -->
      </div>
      </div>
      <div class="certificate_box">
      <img src="../../assets/img/certificate.png" alt="" class="certificate" />
      <p>
        <a
          target="_blank"
          href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44010602010509"
          style="font-size: 14px; color: #939393"
          >粤公网安备 44010602010509号</a
        >
        &nbsp;&nbsp;&nbsp;&nbsp;
        <a
          target="_blank"
          href="https://beian.miit.gov.cn/"
          style="font-size: 14px; color: #939393; display: flex"
          >粤ICP备17095452号</a
        >
      </p>
    </div>
    </div>

    

    <!-- <div
      style="
        display: flex;
        width: 100%;
        height: 96px;
        background-color: #494949;
        justify-content: center;
        align-items: center;
        flex-direction: column;
      "
    >
      <div style="display: flex; flex-direction: row">
        <img src="../../assets/img/gongan.png" />
        <a
          target="_blank"
          href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44010602010509"
          style="font-size: 13px; color: #939393"
          >粤公网安备 44010602010509号</a
        >
        &nbsp;&nbsp;&nbsp;&nbsp;
        <a
          target="_blank"
          href="https://beian.miit.gov.cn/"
          style="font-size: 13px; color: #939393; display: flex"
          >粤ICP备17095452号</a
        >
      </div>
      <div
        style="display: flex; font-size: 13px; color: #939393; margin-top: 5px"
      >
        海闻科技有限公司版权所有
      </div>
      <div
        style="display: flex; font-size: 13px; color: #939393; margin-top: 5px"
      >
        为保证浏览体验，建议使用谷歌浏览器
      </div>
    </div> -->
  </footer>
</template>

<script setup lang="ts">
import Bus from "/@/utils/bus";
// import { onBeforeUnmount } from "vue";

function goRouter(r) {
  Bus.emit("goRouter", r);
}

function help() {
  window.open('https://baoxiao666.com/doc/help/', "_blank")
}
//
// onBeforeUnmount(() => {
//   Bus.off("goRouter");
// });
</script>

<style scoped>
.logo_white {
  width: 158px;
  height: 25px;
}
.certificate_box {
  width: 100%;
  min-width: max-content;
  padding-bottom: 16px;
  background-color: #0b273f;
  margin-top: 20px;
}
.certificate {
  width: 250px;
  margin: 0 auto;
}
.certificate_box>p {
  /* width: 250px; */
  height: 13px;
  font-size: 10px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 13px;
  margin-top: 21px;
  opacity: 0.6;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
