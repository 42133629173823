<template>
  <div class="content_img_txt">
           <ul class="flex content_img_txt_ul">
               <li class="content_img_txt_li" v-for="(item, index) in state.contentList" :key="index">
                   <img :src="item.img" alt="">
                   <!-- <p class="relative"><span>{{item.text1}}</span></p> -->
                   <p class="relative"><span class="absolute"></span><span>{{item.text1}}</span></p>
                   <p v-html="item.text2"></p>
               </li>
           </ul>
      </div>
</template>

<script setup lang="ts">
import { reactive, watchEffect } from "vue";
import getAssetsFile from "/@/assets/img/image_js/image_js";
import registration from "./registration.vue";


const state = reactive({
  contentList:[
      {
          img:getAssetsFile.getAssetsFile("/aboutus/gsln2.png"),
          text1:'公司理念',
          text2:'合规、专业、效率、增值'
      },{
          img:getAssetsFile.getAssetsFile("/aboutus/ppnh2.png"),
          text1:'品牌内涵',
          text2:'财税SAAS云 + 财税BPO'
      },{
          img:getAssetsFile.getAssetsFile("/aboutus/hxjz2.png"),
          text1:'价值核心',
          text2:'数字财税、客企双赢'
      },
  ]
});
</script>

<style scoped>
.flex {
  display: flex;
}
.relative {
  position: relative;
}
.content_img_txt {
  width: 1200px;
  margin: 0 auto;
}
.content_img_txt_ul {
    justify-content: space-between;
}
.content_img_txt_li {
    width: 372px;
    height: 306px;
    background: #FFFFFF;
    box-shadow: 0px 0px 50px 0px rgba(144,160,179,0.16);
    border-radius: 8px;
    padding: 15px;
    box-sizing: border-box;
}
.content_img_txt_li>img {
    width: 342px;
    height: 182px;
}
.content_img_txt_li>p {
    text-align: center;
    margin-top: 17px;
}
.content_img_txt_li>p:nth-child(2) {
    width: 88px;
    margin: 0 auto;
    margin-top: 24px;
    height: 22px;
    font-size: 22px;
    font-family: SourceHanSansCN-Bold, SourceHanSansCN;
    font-weight: bold;
    color: #202125;
    line-height: 33px;
}
.content_img_txt_li>p>span:nth-child(1) {
    width: 84px;
    height: 6px;
    background: linear-gradient(270deg, #FFFFFF 0%, #BCF5F2 47%, #41C7C0 100%);
    border-radius: 2px;
    bottom: -8px;
    left: 0;
}
.content_img_txt_li>p>span:nth-last-child(1) {
    z-index: 1;
    position: absolute;
    width: max-content;
    display: inline-block;
    left: 0;
}
.content_img_txt_li>p:nth-last-child(1) {
    font-size: 16px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #666;
    line-height: 24px;
}
</style>