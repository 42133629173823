<template>
  <!-- 个人信息 -->
  <!-- <div class="message"> -->
  <div class="fixed_box">
    <div
      class="fixed-right"
      @mouseover="showFn4()"
      @mouseleave="showFn7()"
      style="border-radius:79px 79px 0 0;"
    >
      <img src="../../assets/img/fixed-img1.png" alt="" />
      <p>客服</p>
    </div>
    
    <div
      class="fixed-right"
      @mouseover="showFn5()"
      @mouseleave="showFn8()"
    >
      <img src="../../assets/img/fixed-img2.png" alt="" />
      <p>微信</p>
    </div>
    
    <div
      class="fixed-right"
      @mouseover="showFn6()"
      @mouseleave="showFn9()"
      style="border-radius: 0 0 79px 79px;"
    >
      <img src="../../assets/img/fixed-img3.png" alt="" />
      <p>体验</p>
    </div>
  </div>
  <div @click="bankTop" class="bankTop">
    <img src="../../assets/img/top.png" alt="">
  </div>
  <transition name="plus-icon">
    <div class="fixed-left message-left" v-if="show1">
      <p>请拨打我们的热线电话</p>
      <p>400-059-6663</p>
      <!-- <p>137******** 张先生</p>
      <p>186******** 赵先生</p> -->
    </div>
  </transition>

  <!-- </div> -->
  <!-- 企业微信二维码 -->
  <div class="code">
    <transition name="plus-icon">
      <div class="fixed-left code-left" v-if="show2">
        <p>给您提供更专业的服务，请添加客服企业微信</p>
        <div class="code-img">
          <div>
            <img class="code-img1" src="../../assets/img/fixed-img6.png" alt="" />
            <p>客服</p>
          </div>
          <div>
            <img class="code-img2" src="../../assets/img/fixed-img4.png" alt="" />
            <p>技术运维</p>
          </div>
          <div>
            <img class="code-img2" src="../../assets/img/fixed-img5.png" alt="" />
            <p>技术运维</p>
          </div>
          
          
        </div>
      </div>
    </transition>
  </div>
  <!-- 小程序码 -->
  <div class="program">
    <transition name="plus-icon">
      <div class="fixed-left program-left" v-if="show3">
        <p>请扫描小程序二维码进行体验</p>
        <img
          src="../../assets/img/fixed-img6.jpg"
          width="120"
          height="120"
          alt=""
        />
      </div>
    </transition>
  </div>
</template>
<script setup lang="ts">
import { ref } from "vue";
const show1 = ref(false);
const show2 = ref(false);
const show3 = ref(false);
const hide1 = ref(false);
const hide2 = ref(false);
const hide3 = ref(false);
function showFn1() {
  show1.value = !show1.value;
  hide1.value = !hide1.value;
}
function showFn2() {
  show2.value = !show2.value;
  hide2.value = !hide2.value;
}
function showFn3() {
  show3.value = !show3.value;
  hide3.value = !hide3.value;
}
function showFn4() {
  show1.value = true;
  // hide1.value = true
}
function showFn5() {
  show2.value = true;
  // hide2.value = true
}
function showFn6() {
  show3.value = true;
  // hide3.value = true
}
function showFn7() {
  show1.value = false;
  hide1.value = false;
}
function showFn8() {
  show2.value = false;
  hide2.value = false;
}
function showFn9() {
  show3.value = false;
  hide3.value = false;
}
function bankTop() {
  scrollTo(0,0);
}
</script>
<style scoped>
/* .fixed {
  position: fixed;
  right: 0px;
  bottom: 14px;
  width: 440px;
  height: 505px;
} */
/* .message {
  position: fixed;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  height: 137px;
} */
.code {
  display: flex;
  align-items: center;
  /*margin-bottom: 15px;*/
  /*height: 169px;*/
}
.program {
  display: flex;
  align-items: center;
  /*height: 169px;*/
}
/* .fixed-left {
  width: 343px;
  background: #ffffff;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.3),
    0px 4px 10px 0px rgba(0, 0, 0, 0.3), 0px 4px 10px 0px rgba(0, 0, 0, 0.3);
} */
.message-left {
  position: fixed;
  bottom: 200px;
  right: 165px;
  /* height: 100px; */
  padding: 10px 19px;
  box-sizing: border-box;
  z-index: 3;
  width: 207px;
  height: 78px;
  background: #FFFFFF;
  border-radius: 10px;
  box-shadow: 0px 6px 30px 0px rgba(161,180,204,0.3);
  opacity: 0.97;
  border: 1px solid;
  border-image: linear-gradient(138deg, rgba(253, 254, 255, 0.6), rgba(250, 252, 255, 0.45)) 1 1;
}
.code-left {
  position: fixed;
  bottom: 100px;
  right: 165px;
  padding: 15px 28px;
  border-radius: 10px;
  z-index: 3;
  width: 435px;
  height: 212px;
  background: #FFFFFF;
  box-shadow: 0px 6px 30px 0px rgba(161,180,204,0.3);
  opacity: 0.97;
  border: 1px solid;
  border-image: linear-gradient(138deg, rgba(253, 254, 255, 0.6), rgba(250, 252, 255, 0.45)) 1 1;
  /*height: 169px;*/
}
.program-left {
  position: fixed;
  bottom: 50px;
  right: 165px;
  padding: 20px 50px;
  border-radius: 10px;
  /*height: 169px;*/
  z-index: 3;
  width: 295px;
  height: 200px;
  background: #FFFFFF;
  box-shadow: 0px 6px 30px 0px rgba(161,180,204,0.3);
  opacity: 0.97;
  border: 1px solid;
  border-image: linear-gradient(138deg, rgba(253, 254, 255, 0.6), rgba(250, 252, 255, 0.45)) 1 1;
}
.fixed-left p {
  /* margin-left: 57px; */
  font-size: 18px;
  /* height: 34px;
  line-height: 34px; */
  font-family: SourceHanSansCN-Regular;
  color: #797979;
}
.message-left p:nth-child(1) {
  /* margin-top: 20px; */
  margin: 0;
  font-size: 14px;
}
.message-left p:nth-child(2) {
  /* margin-top: 20px; */
  font-size: 18px;
  margin: 0;
  margin-top: 10px;
  /* margin-left: 100px; */
color: #33CDCE;
line-height: 27px;
}
.code-left p {
  font-size: 14px;
  /* margin-left: 31px; */
  margin-top: 4px;
}
.code-left .code-img {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 10px;
}
.code-left .code-img p {
  text-align: center;
}
.code-left .code-img1 {
  /* display: flex;
  justify-content: center;
  align-items: center; */
  width: 120px;
  height: 120px;
  /* margin-left: 45px; */
  /* background-image: url(../../assets/img/fixed-img4.png); */
}
.code-left .code-img2 {
  width: 120px;
  height: 120px;
  margin-left: 10px;
}
.program-left p {
  font-size: 14px;
}
.program-left img {
  /* margin-left: 105px; */
  margin: 0 auto;
  width: 120px;
  height: 120px;
  margin-top: 10px;
}
.fixed_box {
  border-radius: 79px;
  width: 52px;
  height: 186px;
  box-shadow: 0px 3px 19px 0px rgba(144, 184, 236, 0.2);
  background: #FFF;
  position: fixed;
  right: 100px;
  bottom: 100px;
  z-index: 2;
}
.fixed_box img {
  width: 24px;
  height: 24px;
  margin: 0 auto;
}
.fixed_box p{
  font-size: 12px;
  color: #202125;
  text-align: center;
  height: 34px;
  line-height: 27px;
  border-bottom: 1px solid #EAECF1;
  width: fit-content;
    margin: 0 auto;
}
.fixed_box>div:nth-last-child(1)> p {
  border: none;
}
.fixed_box>div {
  height: 62px;
  padding-top: 10px;
}
/* .fixed-right {
  width: 41px;
  height: 49px;
} */
.fixed-right > img {
  width: 19px;
  height: 19px;
}
.fixed-right:hover {
  
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.075),
    1px 4px 10px 0px rgba(24, 24, 24, 0.116), 0px 4px 10px 0px rgba(29, 29, 29, 0.116);
  /* box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.3),
    1px 4px 10px 0px rgba(0, 0, 0, 0.3), 0px 4px 10px 0px rgba(0, 0, 0, 0.3); */
}
.message-right {
  position: fixed;
  bottom: 292px;
  right: 37px;
}
.code-right {
  position: fixed;
  bottom: 149px;
  right: 37px;
}
.program-right {
  position: fixed;
  bottom: 70px;
  right: 37px;
}
.program-right img {
  width: 25px;
}
.boult1 {
  position: fixed;
  right: 5px;
  bottom: 310px;
}
.boult2 {
  position: fixed;
  right: 5px;
  bottom: 161px;
}
.boult3 {
  position: fixed;
  right: 5px;
  bottom: 81px;
}
.plus-icon-enter-active {
  transition: opacity 0.7s;
}
.plus-icon-enter-from {
  opacity: 0;
}

.plus-icon-leave-active {
  transition: opacity 0.7s;
}
.plus-icon-leave-to {
  opacity: 0;
}
.bankTop {
  position: fixed;
  bottom: 35px;
  right: 100px;
  width: 52px;
  height: 52px;
  background: #FFFFFF;
  box-shadow: 0px 4px 24px 0px rgba(144,184,236,0.2);
  backdrop-filter: blur(7px);
  border-radius: 50%;
  z-index: 3;
}
.bankTop>img {
  width: 20px;
  height: 23px;
  margin: 16px auto;
}

.bankTop:hover {
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.075),
    1px 4px 10px 0px rgba(24, 24, 24, 0.116), 0px 4px 10px 0px rgba(29, 29, 29, 0.116);
}
</style>
