<template>
  <!-- <section id="header_banner"> -->
    <div class="header_banner relative">
      <img src="../../assets/img/buy/buy_b_bg.png" alt="" />
      <div class="content">
          <p class="tit1">支持企业私有化部署/个性化定制</p>
          <p class="tit2">涵盖所有功能，一对一专属服务支持</p>
          <div class="flex banner_b_btn"  @click="mikecrm">
              <img src="../../assets/img/buy/customer.png" alt="" class="customer">
              <span>联系客服</span>
          </div>
      </div>
    </div>
  <!-- </section> -->
</template>

<script setup lang="ts">
import {useRouter} from "vue-router";
import {onBeforeUnmount, reactive, watchEffect} from "vue";
import Bus from "/@/utils/bus";
import {ROUTER_CACHE_KEY} from "/@/enums/cacheEnum";

const state = reactive({
})

function mikecrm() {
  window.open('http://seapower.mikecrm.com/udpwVLB', "_blank")
}
function login() {
  window.open('https://baoxiao666.com/pl', "_blank")
}
</script>

<style scoped>
div,
li,
p,
span {
  cursor: context-menu;
}
.flex {
  display: flex;
  align-items: center;
}
.relative {
  position: relative;
}
.relative > img {
  width: 100%;
}
.relative > div {
  position: absolute;
  /* top: 0;
  width: 100%;
  height: 100%; */
  z-index: 1;
}
.absolute {
  position: absolute;
}

.header_banner {
    width: 1200px;
    margin: 30px auto;
    margin-top: 70px;
}
.tit1 {
    font-size: 36px;
    font-family: SourceHanSansCN-Bold, SourceHanSansCN;
    font-weight: bold;
    color: #193534;
    line-height: 54px;
}
.tit2 {
    font-size: 24px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #648382;
    line-height: 33px;
    margin: 16px 0;
}
.content {
    top: 25px;
    left: 53px;
}
.customer {
    width: 20px;
    height: 20px;
    margin-right: 8px;
}
.banner_b_btn {
  width: 142px;
  height: 42px;
  background: #33CDCE;
  border-radius: 4px;
  font-size: 16px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 42px;
  justify-content: center;
}
</style>