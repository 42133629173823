import {createApp} from 'vue'
import App from './App.vue'
import {router} from '/@/router'
import 'normalize.css/normalize.css'
import './assets/css/reset.css'
import 'animate.css/animate.min.css'
// import naive from 'naive-ui'
// import Antd from 'ant-design-vue';
// import 'ant-design-vue/dist/antd.css';
// import 'vfonts/Lato.css' // 通用字体
// import 'vfonts/FiraCode.css' // 等宽字体
import 'virtual:windi.css'
const app = createApp(App)
app.use(router)
// app.use(naive)
// app.use(Antd)
app.mount('#app')
