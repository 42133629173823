<template>
  <carousel></carousel>
  <introduce></introduce>
  <registration></registration>

  <!-- nav -->
  <section id="module">
    <div
      style="
        font-size: 36px;
        font-weight: 500;
        color: #222222;
        margin-top: 20px;
        text-align: center;
      "
    >
      6大核心功能助您轻松报销
    </div>
    <div class="tit">发票管理、线上闭环报销、智能化费用归集</div>
    <div class="navList">
      <div
        v-for="(item, index) in state.navIconTitle"
        :key="index"
        @mousemove="setNavIndex(index)"
        style="width: 200px; text-align: center; margin-left: 10px"
      >
        <img
          :src="state.navIconList[index]"
          alt=""
          style="height: 24px; width: 28px; margin: 0 auto"
        />
        <div
          style="
            font-size: 16px;
            height: 30px;
            font-weight: 500;
            margin-top: 11px;
          "
          :class="[index === state.navIndex ? 'button-active' : '']"
        >
          {{ item }}
        </div>
      </div>
    </div>
    <div class="sliderBox">
      <div
        class="slider"
        :style="'transform: translateX(' + state.navIndex * 153 + 'px)'"
      ></div>
    </div>

    <section id="moduleContent">
      <div class="navcontent_box">
        <div class="navcontent_tit">
          {{ state.navContent[state.navIndex].topTit }}
        </div>
        <div class="navcontent flex">
          <div class="navcontent_text">
            <ul>
              <li
                :style="
                  state.navContent[state.navIndex].textBg ? '' : 'width:496px'
                "
                v-for="(item, index) in state.navContent[state.navIndex].ulObj"
                :key="index"
              >
                <div
                  :class="
                    state.navContent[state.navIndex].textBg ? 'naccontent' : ''
                  "
                >
                  <p
                    :class="
                      state.navContent[state.navIndex].textBg
                        ? 'naccontent_text_tit2'
                        : 'naccontent_text_tit'
                    "
                  >
                    {{ item.tit }}
                  </p>
                  <p
                    :class="
                      state.navContent[state.navIndex].textBg
                        ? 'naccontent_text_tip2'
                        : 'naccontent_text_tip'
                    "
                  >
                    {{ item.content }}
                  </p>
                </div>
                <img
                  src="../../assets/img/navtextbg1.png"
                  alt=""
                  class="textbg"
                  v-if="state.navContent[state.navIndex].textBg"
                />
              </li>
            </ul>
          </div>
          <div class="navcontent2">
            <img
              :src="state.navContent[state.navIndex].imgBg"
              :style="'width:' + state.navContent[state.navIndex].imgWidth"
              alt=""
            />
          </div>
          <div
            class="navcontent_text"
            v-if="state.navContent[state.navIndex].textBg"
          >
            <ul>
              <li
                v-for="(item, index) in state.navContent[state.navIndex]
                  .rightList"
                :key="index"
              >
                <div class="naccontent2">
                  <p class="naccontent_text_tit2">{{ item.tit }}</p>
                  <p class="naccontent_text_tip2">
                    {{ item.content }}
                  </p>
                </div>
                <img
                  src="../../assets/img/navtextbg2.png"
                  alt=""
                  class="textbg"
                />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  </section>

  <!-- 广告 -->
  <section id="advert">
    <div class="advertisement">
      <div class="advertisement_tit">
        <p>以发票、费用管理为抓手</p>
        <p style="margin: 6px">成为企业财税智慧化首选工具</p>
      </div>
      <div class="advertisement_box flex">
        <div class="advertisement_left">
          <p
            v-for="(item, index) in state.advertisementLeft"
            :key="index"
            @mousemove="setAdvertisementIndex(index)"
            :class="[
              index === state.advertisementIndex
                ? 'advertisement_leftp_active'
                : 'advertisement_leftp',
            ]"
          >
            {{ item }}
          </p>
        </div>
        <div class="advertisement_right">
          <ul>
            <li
              v-for="(item, index) in state.advertisementRightList[
                state.advertisementIndex
              ]"
              :key="index"
            >
              <span></span> <span v-html="item"></span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <img
      src="../../assets/img/advertisementbg.png"
      alt=""
      srcset=""
      class="advertisementbg"
    />
  </section>

  <section id="personBox">
    <div class="personBox floor-5">
      <div
        style="
          font-size: 36px;
          font-weight: 500;
          color: #222222;
          margin-top: 20px;
          text-align: center;
        "
      >
        资深财税领域专家，为您提供专业支撑
      </div>
      <div class="tit">资深财税专家专业保障，让企业财税管理合规</div>
      <div class="rc-bricks">
        <div
          class="rc-bricks__item"
          v-for="(item, index) in state.persionList"
          :key="index"
        >
          <div class="contact-card">
            <div class="contact-card__info">
              <div class="contact-card__avatar persion_avatar_box flex">
                <img
                  class="contact-card__pic persion_avatar"
                  :src="item.img"
                  alt=""
                />
                <div style="margin-left: 10px">
                  <strong class="contact-card__name persion_avatar_text_p1">{{
                    item.name
                  }}</strong>
                  <p class="contact-card__title persion_avatar_text_p2">
                    {{ item.text }}
                  </p>
                </div>
              </div>
            </div>
            <ul class="contact-card__statistics persion_time">
              <li class="persion_time1">
                <span>从业时长：</span><strong>{{ item.time }}</strong>
              </li>
              <li class="persion_time1">
                <span>擅长领域：</span><strong>{{ item.content }}</strong>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section id="enterprise">
    <div class="enterprise">
      <img
        src="../../assets/img/enterprisebg.png"
        alt=""
        class="enterprise_bg_big"
      />
      <div class="enterprise_content">
        <div
          style="
            font-size: 34px;
            font-weight: 500;
            color: #222222;
            margin-top: 63px;
            text-align: center;
          "
        >
          为100+企业用户提供财税数字化服务
        </div>
        <div class="tit" style="margin-bottom: 14px">
          海闻科技研发的产品正广泛应用于银行、保险、航空、能源、制造等10多个行业
        </div>
        <div class="enterprise_header_bth" @click="goRouter('Scene')">
          了解报销场景解决方案＞
        </div>
        <div class="enterprise_content_box enterprise">
          <ul
            class="flex"
            v-for="(item, index) in state.enterpriseList"
            :key="index"
          >
            <li class="flex" style="align-items: flex-start">
              <div class="enterprise enterprise_li">
                <img :src="item.img" alt="" class="enterprise_bg" />
                <p>{{ item.text }}</p>
              </div>
              <div
                class="flex scroll_box scroll_bar"
                style="position: relative; height: 98px"
              >
                <ul
                  class="flex absolute"
                  :style="
                    item.left != 0
                      ? 'left:-' +
                        item.left +
                        'px;transition: left 0.7s linear;'
                      : 'left:-' + item.left + 'px;transition: left 0s linear;'
                  "
                >
                  <li
                    v-for="(item2, index2) in item.ul"
                    :key="index2"
                    class="enterprise_li2"
                  >
                    <img :src="item2" alt="" />
                  </li>
                </ul>
              </div>
            </li>
          </ul>
          <div class="enterprise_left_bg enterprise_bg_all"></div>
          <div class="enterprise_right_bg enterprise_bg_all"></div>
        </div>
      </div>
    </div>
  </section>

  <!-- <section
    id="body1"
    style="display: flex; width: 100%; height: 330px; flex-direction: column"
  >
    <div
      style="
        display: flex;
        font-size: 34px;
        font-weight: 500;
        color: #222222;
        justify-content: center;
        align-items: center;
        margin-top: 56px;
        margin-bottom: 43px;
      "
    >
      小微企业线上报销神器
    </div>
    <div
      style="
        display: flex;
        height: 145px;
        padding-right: 100px;
        padding-left: 100px;
        justify-content: space-between;
      "
    >
      <div
        v-for="(item, index) in state.body1Texts"
        :key="index"
        @mousemove="setBody1Index(index)"
        style="
          width: 200px;
          display: flex;
          height: 175px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        "
      >
        <img
          :src="state.body1Imgs[index]"
          alt=""
          style="height: 107px; width: 204px; display: flex"
        />
        <div
          style="
            display: flex;
            justify-content: center;
            flex-grow: 1;
            font-size: 20px;
            width: 204px;
            height: 56px;
            font-weight: 500;
            font-family: PingFang SC-Medium;
            margin-top: 22px;
            padding-bottom: 28px;
          "
          :class="[index === state.body1Index ? 'button-active' : '']"
        >
          {{ item }}
        </div>
      </div>
    </div>
  </section>
  <section
    id="body2"
    style="
      display: flex;
      width: 100%;
      height: 573px;
      flex-direction: column;
      background-color: #f4f7f8;
      position: relative;
    "
  >
    <div
      style="
        position: absolute;
        width: 530px;
        height: 100%;
        top: 145px;
        left: 100px;
        flex-direction: column;
        display: flex;
      "
    >
      <div
        style="
          font-size: 23px;
          font-weight: 500;
          color: #222222;
          margin-bottom: 37px;
        "
        v-html="state.body2TitleTexts[state.body1Index]"
      ></div>
      <div
        v-for="(item, index) in state.body2Texts[state.body1Index]"
        :key="index"
        class="body2-li"
        style="
          display: flex;
          color: #a2a4a6;
          margin-top: 5px;
          font-size: 15px;
          width: 497px;
        "
      >
        {{ item }}
      </div>
    </div>
    <div>
      <img
        :src="state.body2Imgs[state.body1Index]"
        style="height: 573px; float: right"
      />
    </div>
  </section>
  <section
    id="body3"
    style="
      display: flex;
      width: 100%;
      height: 140px;
      flex-direction: column;
      background-color: white;
      position: relative;
      font-size: 32px;
      font-weight: 500;
      color: #222222;
      justify-content: center;
      align-items: center;
    "
  >
    个人票据管理中心
  </section>
  <section
    id="body4"
    style="
      display: flex;
      width: 100%;
      height: 592px;
      flex-direction: column;
      background-color: white;
      position: relative;
    "
  >
    <div style="display: flex; flex-direction: row; position: relative">
      <div
        style="
          display: flex;
          width: 50%;
          flex-direction: column;
          height: 296px;
          border: 1px solid #eee;
          position: relative;
        "
      >
        <div
          style="
            position: absolute;
            width: 380px;
            height: 100%;
            top: 42px;
            left: 100px;
            flex-direction: column;
            display: flex;
          "
        >
          <div style="font-size: 23px; font-weight: 500; color: #222222">
            支持多种票据录入方式
          </div>
          <div
            class="body2-li"
            style="
              display: flex;
              color: #a2a4a6;
              margin-top: 28px;
              font-size: 15px;
            "
          >
            拍照录入：采用OCR识别技术，具备容错性高、实时性高、识别准确率高等特性。
          </div>
          <div
            class="body2-li"
            style="
              display: flex;
              color: #a2a4a6;
              margin-top: 5px;
              font-size: 15px;
            "
          >
            扫码录入：手机秒变扫码枪，查验快捷、准确。
          </div>
          <div
            class="body2-li"
            style="
              display: flex;
              color: #a2a4a6;
              margin-top: 5px;
              font-size: 15px;
            "
          >
            手工录入：支持手工添加票据信息，支持全票种录入。
          </div>
          <div
            class="body2-li"
            style="
              display: flex;
              color: #a2a4a6;
              margin-top: 5px;
              font-size: 15px;
            "
          >
            数据对接：支持微信等第三方平台数据对接导入票据，方便快捷。
          </div>
        </div>
        <div>
          <img
            src="../../assets/img/section4-img1.png"
            style="height: 296px; width: 100%; float: right"
          />
        </div>
      </div>
      <div
        style="
          display: flex;
          width: 50%;
          flex-direction: column;
          height: 296px;
          border: 1px solid #eee;
          position: relative;
        "
      >
        <div
          style="
            position: absolute;
            width: 380px;
            height: 100%;
            top: 42px;
            left: 100px;
            flex-direction: column;
            display: flex;
          "
        >
          <div style="font-size: 23px; font-weight: 500; color: #222222">
            支持录入票据种类全
          </div>
          <div
            class="body2-li"
            style="
              display: flex;
              color: #a2a4a6;
              margin-top: 28px;
              font-size: 15px;
            "
          >
            支持录入市面常见报销票据，涵盖各种纸质发票、电子发票、其他可报销单据等。
          </div>
          <div
            class="body2-li"
            style="
              display: flex;
              color: #a2a4a6;
              margin-top: 5px;
              font-size: 15px;
            "
          >
            根据税局或财政部等部门票种更新情况，系统随时更新发票种类。
          </div>
        </div>
        <div>
          <img
            src="../../assets/img/section4-img2.png"
            style="height: 296px; width: 100%; float: right"
          />
        </div>
      </div>
    </div>
    <div style="display: flex; flex-direction: row; position: relative">
      <div
        style="
          display: flex;
          width: 50%;
          flex-direction: column;
          height: 296px;
          border: 1px solid #eee;
          position: relative;
        "
      >
        <div
          style="
            position: absolute;
            width: 380px;
            height: 100%;
            top: 42px;
            left: 100px;
            flex-direction: column;
            display: flex;
          "
        >
          <div style="font-size: 23px; font-weight: 500; color: #222222">
            支持录入票据种字段全
          </div>
          <div
            class="body2-li"
            style="
              display: flex;
              color: #a2a4a6;
              margin-top: 28px;
              font-size: 15px;
            "
          >
            支持录入票据字段齐全，涵盖所有报销相关的数据字段，如发票类型、开票日期、金额等。
          </div>
          <div
            class="body2-li"
            style="
              display: flex;
              color: #a2a4a6;
              margin-top: 5px;
              font-size: 15px;
            "
          >
            支持对小额多量的票据批量录入，直接录入票据总张数及总金额，减轻工作量。
          </div>
        </div>
        <div>
          <img
            src="../../assets/img/section4-img3.png"
            style="height: 296px; width: 100%; float: right"
          />
        </div>
      </div>
      <div
        style="
          display: flex;
          width: 50%;
          flex-direction: column;
          height: 296px;
          border: 1px solid #eee;
          position: relative;
        "
      >
        <div
          style="
            position: absolute;
            width: 380px;
            height: 100%;
            top: 42px;
            left: 100px;
            flex-direction: column;
            display: flex;
          "
        >
          <div style="font-size: 23px; font-weight: 500; color: #222222">
            个人票包随心分类
          </div>
          <div
            class="body2-li"
            style="
              display: flex;
              color: #a2a4a6;
              margin-top: 28px;
              font-size: 15px;
            "
          >
            预置默认票包，添加发票即可查看，形成个人票据电子档案。
          </div>
          <div
            class="body2-li"
            style="
              display: flex;
              color: #a2a4a6;
              margin-top: 5px;
              font-size: 15px;
            "
          >
            支持票包自定义，随心票据分类。
          </div>
        </div>
        <div>
          <img
            src="../../assets/img/section4-img4.png"
            style="height: 296px; width: 100%; float: right"
          />
        </div>
      </div>
    </div>
  </section>
  <section
    id="body5"
    style="
      display: flex;
      width: 100%;
      height: 317px;
      flex-direction: column;
      background-color: #f4f7f8;
    "
  >
    <div
      style="
        display: flex;
        height: 140px;
        font-size: 34px;
        font-weight: 500;
        color: #222222;
        justify-content: center;
        align-items: center;
        font-family: PingFang SC-Medium;
      "
    >
      费控管理工具，助力企业降本增效
    </div>
    <div
      style="
        display: flex;
        height: 170px;
        justify-content: space-between;
        padding-right: 100px;
        padding-left: 100px;
      "
    >
      <div
        v-for="(item, index) in state.body5TitleTexts"
        @mousemove="setBody5Index(index)"
        :key="index"
        style="
          width: 200px;
          display: flex;
          height: 145px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        "
      >
        <img
          :src="state.body5Imgs[index]"
          alt=""
          style="height: 107px; width: 204px; display: flex; margin-top: 20px"
        />
        <div
          style="
            display: flex;
            justify-content: center;
            flex-grow: 1;
            font-size: 20px;
            font-weight: 500;
            margin-top: 20px;
            font-family: PingFang SC-Medium;
            width: 204px;
            height: 56px;
            padding-bottom: 28px;
          "
          :class="[index === state.body5Index ? 'button-active' : '']"
        >
          {{ item }}
        </div>
      </div>
    </div>
  </section>
  <section
    id="body6"
    style="
      display: flex;
      width: 100%;
      height: 432px;
      flex-direction: column;
      background-color: #f4f7f8;
      position: relative;
    "
  >
    <div
      style="
        position: absolute;
        width: 380px;
        height: 100%;
        top: 101px;
        left: 100px;
        flex-direction: column;
        display: flex;
      "
    >
      <div
        style="
          font-size: 23px;
          font-weight: 500;
          color: #222222;
          margin-bottom: 37px;
        "
      >
        {{ state.body6TitleTexts[state.body5Index] }}
      </div>
      <div
        v-for="(item, index) in state.body6Texts[state.body5Index]"
        :key="index"
        class="body2-li"
        style="display: flex; color: #a2a4a6; margin-top: 5px; font-size: 15px"
      >
        {{ item }}
      </div>
    </div>
    <div>
      <img
        :src="state.body6Imgs[state.body5Index]"
        style="height: 432px; float: right"
      />
    </div>
  </section>
  <section
    id="body7"
    style="display: flex; width: 100%; height: 300px; flex-direction: column"
  >
    <div
      style="
        display: flex;
        height: 160px;
        font-size: 32px;
        font-weight: 500;
        color: #222222;
        justify-content: center;
        align-items: center;
      "
    >
      资深财税领域专家，为您提供专业支撑
    </div>
    <div
      style="
        display: flex;
        height: 145px;
        justify-content: space-between;
        padding-right: 100px;
        padding-left: 100px;
      "
    >
      <div
        style="
          width: 204px;
          display: flex;
          height: 145px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        "
        v-for="(item, index) in state.body7Texts"
        :key="index"
        @mousemove="setBody7Index(index)"
      >
        <img
          :src="state.body7Imgs[index]"
          alt=""
          style="height: 107px; width: 204px; display: flex; margin-top: 20px"
        />
        <div
          style="
            display: flex;
            justify-content: center;
            flex-grow: 1;
            width: 204px;
            height: 56px;
            padding-bottom: 28px;
            font-size: 20px;
            font-weight: 500;
            /* color: #41c7c0; */
            margin-top: 20px;
            /* border-bottom: 3px solid #41c7c0; */
          "
          :class="[index === state.body7Index ? 'button-active' : '']"
        >
          {{ item }}
        </div>
      </div>
      注释开始
      <div
        style="
          width: 204px;
          display: flex;
          height: 145px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        "
      >
        <img
          src="../../assets/img/section7-img5.png"
          alt=""
          style="height: 107px; width: 204px; display: flex; margin-top: 20px"
        />
        <div
          style="
            display: flex;
            justify-content: center;
            flex-grow: 1;
             width: 204px;
            height: 56px;
            padding-bottom: 28px;
            font-size: 20px;
            font-weight: 500;
            color: #222222;
            margin-top: 20px;
          "
        >
          业务专业度支撑
        </div>
      </div>
      <div
        style="
          width: 204px;
          display: flex;
          height: 145px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        "
      >
        <img
          src="../../assets/img/section7-img6.png"
          alt=""
          style="height: 107px; width: 204px; display: flex; margin-top: 20px"
        />
        <div
          style="
            display: flex;
            justify-content: center;
            flex-grow: 1;
             width: 204px;
            height: 56px;
            padding-bottom: 28px;
            font-size: 20px;
            font-weight: 500;
            color: #222222;
            margin-top: 20px;
          "
        >
          技术专业度支撑
        </div>
      </div>
      注释结束
    </div>
  </section>
  <section
    id="body8"
    style="
      display: flex;
      width: 100%;
      height: 432px;
      flex-direction: column;
      position: relative;
    "
  >
    <div
      style="
        display: flex;
        height: 432px;
        justify-content: space-between;
        align-items: center;
        padding-right: 100px;
        padding-left: 100px;
      "
      v-if="state.body7Index === 0"
    >
      <div
        style="
          width: 280px;
          height: 246px;
          display: flex;
          box-shadow: 0px 6px 21px 1px rgba(0, 0, 0, 0.09);
          border-radius: 5px 5px 5px 5px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        "
      >
        <img
          src="../../assets/img/section8-img1.png"
          alt=""
          style="height: 156px; width: 156px; display: flex; margin-top: 20px"
        />
        <div
          style="
            display: flex;
            flex-grow: 1;
            font-size: 19px;
            font-weight: 500;
            margin-top: 20px;
          "
        >
          公安部三级等保
        </div>
      </div>
      <div
        style="
          width: 280px;
          height: 246px;
          display: flex;
          box-shadow: 0px 6px 21px 1px rgba(0, 0, 0, 0.09);
          border-radius: 5px 5px 5px 5px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        "
      >
        <img
          src="../../assets/img/section8-img2.png"
          alt=""
          style="height: 156px; width: 156px; display: flex; margin-top: 20px"
        />
        <div
          style="
            display: flex;
            flex-grow: 1;
            font-size: 19px;
            font-weight: 500;
            margin-top: 20px;
          "
        >
          ISO27001
        </div>
      </div>
      <div
        style="
          width: 280px;
          height: 246px;
          display: flex;
          box-shadow: 0px 6px 21px 1px rgba(0, 0, 0, 0.09);
          border-radius: 5px 5px 5px 5px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        "
      >
        <img
          src="../../assets/img/section8-img3.png"
          alt=""
          style="height: 156px; width: 156px; display: flex; margin-top: 20px"
        />
        <div
          style="
            display: flex;
            flex-grow: 1;
            font-size: 19px;
            font-weight: 500;
            margin-top: 20px;
          "
        >
          ISO20000
        </div>
      </div>
    </div>
    <div
      style="
        display: flex;
        height: 432px;
        justify-content: space-between;
        align-items: center;
        padding-right: 200px;
        padding-left: 200px;
      "
      v-else-if="state.body7Index === 1"
    >
      <div
        style="
          width: 320px;
          height: 320px;
          display: flex;
          /* box-shadow: 0px 6px 21px 1px rgba(0, 0, 0, 0.09); */
          /* border-radius: 5px 5px 5px 5px; */
          flex-direction: column;
          justify-content: center;
          align-items: center;
        "
      >
        <img
          src="../../assets/img/section8-img4.png"
          alt=""
          style="width: 320px; display: flex; margin-top: 20px"
        />
        <div
          style="
            /* display: flex; */
            /* flex-grow: 1; */
            font-size: 18px;
            color: #308dfc;
            font-weight: 700;
            margin: 20px 50px 0 50px;
          "
        >
          国内首家企业端税务科技、 &nbsp;&nbsp;智慧税务解决方案提供商
        </div>
        <div style="font-size: 12px; color: #a2a4a6; margin-top: 10px">
          在近百家大中型企业或者集团落地实施、稳定运行
        </div>
      </div>
      <div
        style="
          width: 360px;
          height: 320px;
          display: flex;
          /* box-shadow: 0px 6px 21px 1px rgba(0, 0, 0, 0.09); */
          /* border-radius: 5px 5px 5px 5px; */
          flex-direction: column;
          justify-content: center;
          align-items: center;
        "
      >
        <img
          src="../../assets/img/section8-img5.png"
          alt=""
          style="width: 360px; display: flex; margin-top: 20px"
        />
        <div
          style="
            /* display: flex; */
            /* flex-grow: 1; */
            font-size: 18px;
            color: #308dfc;
            font-weight: 700;
            margin-top: 20px;
          "
        >
          二十年行业深耕, 5A税务所实力保证
        </div>
        <div style="font-size: 12px; color: #a2a4a6; margin-top: 15px">
          专注于提供深度的税务专业服务
        </div>
      </div>
    </div>
    <div
      style="
        display: flex;
        height: 432px;
        justify-content: space-between;
        align-items: center;
        padding-right: 100px;
        padding-left: 100px;
      "
      v-else
    >
      <div
        style="
          width: 250px;
          height: 330px;
          display: flex;
          box-shadow: 0px 6px 21px 1px rgba(0, 0, 0, 0.09);
          border-radius: 5px 5px 5px 5px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          /* background-color: green; */
        "
        v-for="(item, index) in state.body8Imgs"
        :key="index"
      >
        <img :src="item" alt="" style="width: 200px" />
      </div>
    </div>
  </section> -->
</template>

<script setup lang="ts">
import carousel from "./Carousel.vue";
import introduce from "./introduce.vue";
import registration from "./registration.vue";
import { reactive, watchEffect, onBeforeUnmount,ref } from "vue";
import sec1Img5 from "/@/assets/img/section1-img5.png";
import sec1Img6 from "/@/assets/img/section1-img6.png";
import sec1Img7 from "/@/assets/img/section1-img7.png";
import sec1Img8 from "/@/assets/img/section1-img8.png";
import sec2Img1 from "/@/assets/img/section2-img1.png";
import sec2Img2 from "/@/assets/img/section2-img2.png";
import sec2Img3 from "/@/assets/img/section2-img3.png";
import sec2Img4 from "/@/assets/img/section2-img4.png";
import sec5Img4 from "/@/assets/img/section5-img4.png";
import sec5Img5 from "/@/assets/img/section5-img5.png";
import sec5Img6 from "/@/assets/img/section5-img6.png";
import sec6Img1 from "/@/assets/img/section6-img1.png";
import sec6Img2 from "/@/assets/img/section6-img2.png";
import sec6Img3 from "/@/assets/img/section6-img3.png";
import sec7Img4 from "/@/assets/img/section7-img4.png";
import sec7Img5 from "/@/assets/img/section7-img5.png";
import sec7Img6 from "/@/assets/img/section7-img6.png";
import sec8Img6 from "/@/assets/img/section8-img6.png";
import sec8Img7 from "/@/assets/img/section8-img7.png";
import sec8Img8 from "/@/assets/img/section8-img8.png";

import navIcon1 from "/@/assets/img/navIcon1.png";
import navIcon2 from "/@/assets/img/navIcon2.png";
import navIcon3 from "/@/assets/img/navIcon3.png";
import navIcon4 from "/@/assets/img/navIcon4.png";
import navIcon5 from "/@/assets/img/navIcon5.png";
import navIcon6 from "/@/assets/img/navIcon6.png";
import navImgBg1 from "/@/assets/img/navpic1.png";
import navImgBg2 from "/@/assets/img/navpic2.png";
import navImgBg3 from "/@/assets/img/navpic3.png";
import navImgBg4 from "/@/assets/img/navpic4.png";
import navImgBg5 from "/@/assets/img/navpic5.png";
import navImgBg6 from "/@/assets/img/navpic6.png";
import left from "/@/assets/img/navtextbg1.png";
import right from "/@/assets/img/navtextbg2.png";

import { ROUTER_CACHE_KEY } from "/@/enums/cacheEnum";
import { useRouter } from "vue-router";
import Bus from "/@/utils/bus";
import getAssetsFile from "/@/assets/img/image_js/image_js";
import Qrcode from "/@/components/Qrcode/Qrcode.vue";
// import Qrcode from "@/components/Qrcode/Qrcode";
import axios from "/@/utils/request";

const state = reactive({
  body1Index: 0,
  body1Texts: [
    '线上"无触式"闭环流程',
    "报销场景、规则自定义",
    "智能化费用归集",
    "报销风险管控",
  ],
  body1Imgs: [sec1Img5, sec1Img6, sec1Img7, sec1Img8],
  body2Imgs: [sec2Img1, sec2Img2, sec2Img3, sec2Img4],
  body2TitleTexts: [
    `报销不受时间限制，<span style="font-size: 35px; color:#41C7C0; ">&nbsp;提高&nbsp;</span>报销效率`,
    `智能化<span style="font-size: 35px; color:#41C7C0; ">&nbsp;报销规则&nbsp;</span>，应对全方位报销场景`,
    `智能匹配发票<span style="font-size: 35px; color:#8684DC; ">&nbsp;费用归类&nbsp;</span>，实现报销人一键添票 `,
    `报销风险<span style="font-size: 35px; color:#41C7C0; ">&nbsp;管控&nbsp;</span> `,
  ],
  body2Texts: [
    [
      "员工无需线下找领导审批，缩短审批时间。",
      "简化报销流程，让员工避免繁琐的报销流程，全心投入工作，提升报销体验。",
      "手机即可完成报销全流程，无需打印发票、贴票、寻找报销底联，不受报销工具限制。",
      "摆脱传统纸质报销打印，节约纸张等公司运营成本。",
    ],
    [
      "内置日常报销、差旅报销等多种基本报销场景，满足企业基本需要。",
      "支持根据公司报销特点自定义报销类别及报销规则，瞬间满足企业报销需求差异化。",
      "实现一平台多场景全流程的费用管理模式。",
    ],
    [
      "预置企业报销费用归类规则，即可在报销过程中智能匹配发票费用归类，实现报销人一键添票，省心、省时、省事。 ",
      "财务人员节约审票、审单时间，审批时只需关注票据信息，智能、规范、准确。 ",
      "归集规则可自由配置，同时支持企业自定义适用于本企业规则。",
      "标准化API接口，随时对接各类财务软件",
    ],
    [
      "票面信息异常预警：当获得的发票抬头与本企业抬头不一致、发票销售方异常、开票项目明细不符合报销要求时，系统可自动给出提示，保证报销票据合规性。 ",
      "虚假发票识别、预警：系统提供发票查验服务，可辨别发票真伪，对查验不一致或查无此票的票据进行报销拦截，防范报销风险。",
      " 重复报销预警：当员工报销的发票在系统中有其他报销事项时，及时给出提示，报账报销票据的唯一性。 ",
      "发票状态异常变更预警：系统提供的发票查验服务，可以查验出发票状态，当发票状态为红冲或者异常时，可能存在问题，系统自动做出提醒。",
    ],
  ],
  body5Index: 0,
  body5TitleTexts: [
    "费用数据可视化展示",
    "费用数据统计分析",
    "企业费用电子档案",
  ],
  body6TitleTexts: [
    "企业费用一目了然",
    "多维度统计分析",
    "助力企业完成标准档案数字化",
  ],
  body6Texts: [
    [
      "通过可视化数据展示项目支出占比、部门支出占比等信息，让企业费用一目了然。",
    ],
    [
      "多维度统计：支持按照时间、费用类别、项目、组织架构等维度进行费用统计。",
      "为您提供深度数据分析，包括：费用异常请款预警，费用请款趋势分析，企业自定义数据分析。",
    ],
    [
      "为企业构建费用电子档案，助力企业完成财务管理电子化转型。",
      "实现财务管理数据长期或永久保存。",
    ],
  ],
  body5Imgs: [sec5Img4, sec5Img5, sec5Img6],
  body6Imgs: [sec6Img1, sec6Img2, sec6Img3],
  body7Index: 0,
  body7Texts: ["安全信息保障", "业务专业度支持", "技术专业度支持"],
  body7Imgs: [sec7Img4, sec7Img5, sec7Img6],
  body8Imgs: [sec8Img6, sec8Img7, sec8Img8],

  navIconList: [navIcon1, navIcon2, navIcon3, navIcon4, navIcon5, navIcon6],
  navIconTitle: [
    "票包管理",
    "业务审批",
    "财务管理",
    "规则配置",
    "企业管理",
    "统计分析",
  ],
  navIndex: 0,
  navContent: [
    {
      topTit: "灵活多样录票管票 个人发票管理中心",
      ulObj: [
        {
          tit: "获取方式全",
          content:
            "支持拍照识别、扫码获取、手工采集、数据对接等多种发票获取方式。",
        },
        {
          tit: "支持票种全",
          content: "支持全票种录入，并根据政策要求更新发票票种及字段校验规则。",
        },
        {
          tit: "采集效率高",
          content:
            "支持多张识别、批量文件上传等多种批量录入方式，提升发票采集效率。",
        },
      ],
      imgBg: navImgBg1,
      imgWidth: "682px",
    },
    {
      topTit: "全方位覆盖业务场景  报销审批高效又合规",
      ulObj: [
        {
          tit: "费用智能归类",
          content:
            "根据预置企业报销费用归类规则，智能匹配发票费用归类，实现报销人一键添票，省心、省时、省事。",
          bg: left,
        },
        {
          tit: "支持多种审批",
          content:
            "支持员工发起报销、借支、付款、还款等企业财务管理相关的各类审批流程。",
          bg: left,
        },
      ],
      rightList: [
        {
          tit: "提升审批效率",
          content: "支持微信分享单据审批，报销快人一步。",
          bg: right,
        },
        {
          tit: "单据合规校验",
          content: "支持审批人手写签名、单据异常提醒，降低审批风险。",
          bg: right,
        },
      ],
      imgBg: navImgBg2,
      textBg: true,
      imgWidth: "448px",
    },
    {
      topTit: "全流程监控票据 异常状态无所遁形",
      ulObj: [
        {
          tit: "支持流程全面",
          content:
            "支持财务pc端审核单据、支持跨单据种类汇总请款，集中支付等财务管理全流程。",
        },
        {
          tit: "发票状态追踪",
          content:
            "支持财务复核发票，避免发票状态变更对费用管理合规性造成影响。",
        },
      ],
      imgBg: navImgBg3,
      imgWidth: "599px",
    },
    {
      topTit: "币种编号规则任意配置 满足企业多种个性化需求",
      ulObj: [
        {
          tit: "基本规则配置",
          content:
            "可以根据企业实际情况自定义报销模式选择、币种设置、单据编号设置、校验规则设置、签名设置等，以满足企业个性化需求。",
        },
        {
          tit: "配置灵活自由",
          content:
            "支持企业根据自身需求自定义审批规则、费用类别、归集规则，满足企业个性化报销需求。",
        },
        {
          tit: "费用合规配置",
          content: "支持企业根据自身需求配置黑名单、敏感词、发票抬头等内容。",
        },
      ],
      imgBg: navImgBg4,
      imgWidth: "602px",
    },
    {
      topTit: "离在职员工分类管理 权限设置责任划分明晰",
      ulObj: [
        {
          tit: "企业成员管理",
          content:
            "支持对企业成员进行批量导入、邀请、移动、删除等操作，并支持查看已退出企业成员的信息。",
        },
        {
          tit: "企业角色管理",
          content: "支持对人员进行角色配置，充分满足功能权限的划分。",
        },
      ],
      imgBg: navImgBg5,
      imgWidth: "529px",
    },
    {
      topTit: "财务数据汇总查看整理 多维度分析查看资金明细",
      ulObj: [
        {
          tit: "解决老板顾虑",
          content:
            "汇总展示公司范围内的费用支出统计情况以及不同费用类型/项目归属下的费用汇总信息情况，让资金去向一目了然。",
        },
        {
          tit: "汇总财务数据",
          content:
            "展示财务关心的企业费用分析相关内容，例如待处理审批单统计，借出金额、还款金额、待还款金额、付款支出、报销支出等核心财务数据。",
        },
        {
          tit: "个人记账分析",
          content:
            " 根据票包中的发票自动形成个人记账本，并进行智能化的费用分析。",
        },
      ],
      imgBg: navImgBg6,
      imgWidth: "561px",
    },
  ],

  advertisementLeft: [
    "数据安全·专业保障",
    "持续更新·性价比高",
    "降本增效·省心省力",
  ],
  advertisementIndex: 0,
  advertisementRightList: [
    [
      `公安部<span style="color:#33CDCE">三级等级保护</span>，获得由公安机关认可并颁发的国家级信息系统等级认证。`,
      `通过ISO27001、ISO20000信息安全管理体系认证，保证你的<span style="color:#33CDCE">数据安全和隐私</span>。`,
      `国内首家企业端税务科技、<span style="color:#33CDCE">智慧税务</span>解决方案提供商，二十年行业深耕, 5A 税务所实力保证为您提供业务专业支持。`,
    ],
    [
      `功能迭代<span style="color:#33CDCE">持续更新</span>，无需额外付费即可持续享受全新功能。`,
      `限时<span style="color:#33CDCE">免费使用</span>标准版，助力小微企降本增效，开启全线上报销流程。`,
      `最低<span style="color:#33CDCE">仅需998/年</span>，即可升级票靓专业版，在市面同类产品中具有绝对价格优势。`,
    ],
    [
      `员工发票<span style="color:#33CDCE">一键上传</span>，无需手工打印粘单，单据是否合规一目了然。`,
      `财务人员节约审票、审单时间，审批时只需关注票据信息，<span style="color:#33CDCE">智能、规范、准确</span>。标准化API接口，随时对接各类财务软件。`,
      `<span style="color:#33CDCE">可视化数据</span>展示项目支出占比、部门支出占比等信息，让老板对企业费用一目了然。`,
    ],
  ],

  persionList: [
    {
      img: getAssetsFile.getAssetsFile("/person/s.png"),
      name: "宋玮",
      text: "注册会计师、注册税务师、教授(兼职)、硕士研究生导师(兼职)",
      time: "38年",
      content:
        "1985年进入国家税务总局工作，2001年起创办海华税务师事务所，30余年税务领域经验。精通中国税务法规，深谙中国财务、会计制度。先后为众多的能源、航空、电信、金融保险、餐饮等行业世界500强企业提供税务专业服务，擅长行业性税收政策筹划，税务实务经验丰富，是“IT+T”税务管理创新理念的创建者与践行者。",
      hoverPersion: false,
    },
    {
      img: getAssetsFile.getAssetsFile("/person/l.png"),
      name: "罗燕",
      text: "注册税务师、绩效评价师",
      time: "20年",
      content:
        "深耕财税行业20年，多年5A级税务师事务所工作经验，精通中国财税法规，务实型讲师；擅长为中小企业提供组织架构设计、业务流程优化、财税内控管理；体验过中小企业创业、发展、扩张的每一个过程，能与中小企业主共鸣！曾获第一届“ 注税杯”税务知识大赛团体二等奖（全国第三名）。曾服务企业：中油bp、招商银行、拉法基集团、四川双马、爱马仕、迪卡侬等。",
      hoverPersion: false,
    },
    {
      img: getAssetsFile.getAssetsFile("/person/z.png"),
      name: "钟华",
      text: "澳洲注册会计师",
      time: "20年",
      content:
        "财税服务经验逾20年；曾在不同的国际会计师事务所工作逾8年；擅长外籍人员个人所得税服务、外资企业重组合并的税务风险评估、外商投资企业集团税务综合服务、海华税务广州公司负责转让定价及石油化工行业全国服务小组的负责人。",
      hoverPersion: false,
    },
    {
      img: getAssetsFile.getAssetsFile("/person/h.png"),
      name: "何泳",
      text: "中国注册税务师、中国注册税务师协会高端人才",
      time: "20年",
      content:
        "逾20年在国税系统、大型税务师事务所工作经验，精通中国财税法规；有着非常丰富的各行业项目经验，涉及制造、商贸、科技、互联网商务、石油化工、飞机维修、金融保险、餐饮连锁等各行业大中型企业的常规税务审核、税务健康检查、纳税评估、同期资料、税务咨询及税务筹划等涉税服务；主笔中国税务师协会《特别纳税调 整行业指引》的编纂工作。服务客户包括：中国石油、BP石油中国区、百胜集团中国区、汤臣倍健集团广州区域、美心集团中国区域、格力集团、阿里巴巴-优酷公司、南方电网数字研究院、旭日陶瓷集团、山九物流公司等大中型客户。",
      hoverPersion: false,
    },
    {
      img: getAssetsFile.getAssetsFile("/person/c.png"),
      name: "蔡曼莹",
      text: "注册会计师",
      time: "17年",
      content:
        "逾15年财税管理咨询服务经验。专注于为国有石油化工类企业、生活服务类企业及内外资生产制造类企业提供鉴证、顾问及财税合规等综合服务。擅长关联交易同期资料服务、转让定价顾问服务、转让定价防御审计业务等。",
      hoverPersion: false,
    },
    {
      img: getAssetsFile.getAssetsFile("/person/w.png"),
      name: "吴师法",
      text: "广州市知识产权维权援助专家库专家广州市律师协会专家委委员",
      time: "15年",
      content:
        "从事企业民商事案件处理 15 年， 具有丰富的实务经验，熟悉企业管理和合规经营。服务过近百家企业、园区、政府部门，多次参与企业法律培训，处理过大量合同纠纷案件。",
      hoverPersion: false,
    },
  ],

  enterpriseList: [
    {
      img: getAssetsFile.getAssetsFile("/enterprise/insure.png"),
      text: "保险业",
      header: true,
      left: 0,
      index: 0,
      ul: [
        getAssetsFile.getAssetsFile("/logo/insure/insure1.png"),
        getAssetsFile.getAssetsFile("/logo/insure/insure2.png"),
        getAssetsFile.getAssetsFile("/logo/insure/insure3.png"),
        getAssetsFile.getAssetsFile("/logo/insure/insure4.png"),
        getAssetsFile.getAssetsFile("/logo/insure/insure5.png"),
        getAssetsFile.getAssetsFile("/logo/insure/insure6.png"),
        getAssetsFile.getAssetsFile("/logo/insure/insure7.png"),
        getAssetsFile.getAssetsFile("/logo/insure/insure8.png"),
        getAssetsFile.getAssetsFile("/logo/insure/insure9.png"),
        getAssetsFile.getAssetsFile("/logo/insure/insure10.png"),
        getAssetsFile.getAssetsFile("/logo/insure/insure11.png"),
        getAssetsFile.getAssetsFile("/logo/insure/insure12.png"),
        getAssetsFile.getAssetsFile("/logo/insure/insure13.png"),
        getAssetsFile.getAssetsFile("/logo/insure/insure14.png"),
        getAssetsFile.getAssetsFile("/logo/insure/insure15.png"),
        getAssetsFile.getAssetsFile("/logo/insure/insure16.png"),
        getAssetsFile.getAssetsFile("/logo/insure/insure17.png"),
        getAssetsFile.getAssetsFile("/logo/insure/insure18.png"),
        getAssetsFile.getAssetsFile("/logo/insure/insure19.png"),
        getAssetsFile.getAssetsFile("/logo/insure/insure1.png"),
        getAssetsFile.getAssetsFile("/logo/insure/insure2.png"),
        getAssetsFile.getAssetsFile("/logo/insure/insure3.png"),
        getAssetsFile.getAssetsFile("/logo/insure/insure4.png"),
        getAssetsFile.getAssetsFile("/logo/insure/insure5.png"),
      ],
    },
    {
      img: getAssetsFile.getAssetsFile("/enterprise/bank.png"),
      text: "银行业",
      header: true,
      left: 0,
      index: 0,
      ul: [
        getAssetsFile.getAssetsFile("/logo/bank/bank1.png"),
        getAssetsFile.getAssetsFile("/logo/bank/bank2.png"),
        getAssetsFile.getAssetsFile("/logo/bank/bank3.png"),
        getAssetsFile.getAssetsFile("/logo/bank/bank4.png"),
        getAssetsFile.getAssetsFile("/logo/bank/bank5.png"),
        getAssetsFile.getAssetsFile("/logo/bank/bank6.png"),
        getAssetsFile.getAssetsFile("/logo/bank/bank7.png"),
        getAssetsFile.getAssetsFile("/logo/bank/bank8.png"),
        getAssetsFile.getAssetsFile("/logo/bank/bank9.png"),
        getAssetsFile.getAssetsFile("/logo/bank/bank10.png"),
        getAssetsFile.getAssetsFile("/logo/bank/bank11.png"),
        getAssetsFile.getAssetsFile("/logo/bank/bank12.png"),
        getAssetsFile.getAssetsFile("/logo/bank/bank1.png"),
        getAssetsFile.getAssetsFile("/logo/bank/bank2.png"),
        getAssetsFile.getAssetsFile("/logo/bank/bank3.png"),
        getAssetsFile.getAssetsFile("/logo/bank/bank4.png"),
      ],
    },
    {
      img: getAssetsFile.getAssetsFile("/enterprise/finance.png"),
      text: "其他金融业",
      header: true,
      left: 0,
      index: 0,
      ul: [
        getAssetsFile.getAssetsFile("/logo/finance/finance1.png"),
        getAssetsFile.getAssetsFile("/logo/finance/finance2.png"),
        getAssetsFile.getAssetsFile("/logo/finance/finance3.png"),
        getAssetsFile.getAssetsFile("/logo/finance/finance4.png"),
        getAssetsFile.getAssetsFile("/logo/finance/finance5.png"),
        getAssetsFile.getAssetsFile("/logo/finance/finance6.png"),
        getAssetsFile.getAssetsFile("/logo/finance/finance7.png"),
      ],
    },
    {
      img: getAssetsFile.getAssetsFile("/enterprise/aviation.png"),
      text: "航空与能源业",
      header: true,
      left: 0,
      index: 0,
      ul: [
        getAssetsFile.getAssetsFile("/logo/aviation/aviation1.png"),
        getAssetsFile.getAssetsFile("/logo/aviation/aviation2.png"),
        getAssetsFile.getAssetsFile("/logo/aviation/aviation3.png"),
        getAssetsFile.getAssetsFile("/logo/aviation/aviation4.png"),
        getAssetsFile.getAssetsFile("/logo/aviation/aviation5.png"),
        getAssetsFile.getAssetsFile("/logo/aviation/aviation6.png"),
        getAssetsFile.getAssetsFile("/logo/aviation/aviation7.png"),
      ],
    },
    {
      img: getAssetsFile.getAssetsFile("/enterprise/other.png"),
      text: "其他行业",
      header: true,
      left: 0,
      index: 0,
      ul: [
        getAssetsFile.getAssetsFile("/logo/other/other1.png"),
        getAssetsFile.getAssetsFile("/logo/other/other2.png"),
        getAssetsFile.getAssetsFile("/logo/other/other3.png"),
        getAssetsFile.getAssetsFile("/logo/other/other4.png"),
        getAssetsFile.getAssetsFile("/logo/other/other5.png"),
        getAssetsFile.getAssetsFile("/logo/other/other6.png"),
        getAssetsFile.getAssetsFile("/logo/other/other7.png"),
      ],
    },
  ],

});

var timer2 = setInterval(function () {
  state.enterpriseList.map((item) => {
    if (item.index + 7 == item.ul.length) {
      item.left = 0;
      item.index = 0;
    } else {
      item.left += 140;
      item.index++;
    }
  });
}, 2500);

function mouseFn(index) {
  console.log(index);
  state.persionList[index].hoverPersion = true;
}
function mouseFn2(index) {
  console.log(index);
  state.persionList[index].hoverPersion = false;
}
function setBody1Index(index) {
  state.body1Index = index;
}
function login() {
  window.open("https://baoxiao666.com/pl", "_blank");
}

function setBody5Index(index) {
  state.body5Index = index;
}
function setBody7Index(index) {
  state.body7Index = index;
}

function setNavIndex(index) {
  state.navIndex = index;
}
function setAdvertisementIndex(index) {
  state.advertisementIndex = index;
  // console.log(getAssetsFile);
}
const router = useRouter();

function goRouter(r) {
  // sessionStorage.setItem(ROUTER_CACHE_KEY, r)
  // state.routerPath = r
  // console.log(r)
  // router.push(r)
  Bus.emit("goRouter", r);
}
watchEffect(() => {
  console.log(state.body1Index);
  console.log(state.body5Index);
});
</script>

<style scoped lang='less'>
.scroll_bar::-webkit-scrollbar {
  width: 3px !important;
}
/* .scroll_bar { -ms-overflow-style: none; }
.scroll_bar  { overflow: -moz-scrollbars-none; } */
div,
li,
p,
span {
  cursor: context-menu;
}
.body2-li {
  padding-left: 12px;
}

.body2-li::before {
  position: absolute;
  left: 0;
  font-size: 5px;
  content: "●";
}

.button-active {
  color: #41c7c0;
  /* border-bottom: 0.1875rem solid #41c7c0; */
}
.tit {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #45576d;
  line-height: 16px;
  text-align: center;
  margin-top: 8px;
  margin-bottom: 70px;
}
.navList {
  display: flex;
  /* height: 9.0625rem; */
  width: 900px;
  margin: 0 auto;
  justify-content: space-between;
}
.navList > div {
  margin-left: 10px;
}
.navList > div:nth-child(1) {
  margin-left: 0 !important;
}

/*创建容器*/
.sliderBox {
  overflow: hidden;
  width: 850px;
  height: 2px;
  background: #cddbe6;
  margin: 0 auto;
}
/*创建滑动块*/
.slider {
  white-space: nowrap; /*取消文本换行*/
  transition: transform 0.3s ease-out; /*添加过渡效果*/
  width: 85px;
  height: 3px;
  background: #33cdce;
}
/*当触发动画时，改变transform属性的值*/
.slide.left {
  transform: translateX(-100%);
}
.slide.right {
  transform: translateX(100%);
}

.flex {
  display: flex;
  align-items: center;
}
.absolute {
  position: absolute;
}
#module {
  padding: 68px 0 33px;
  background: #f9fcfe;
  height: 850px;
}
.navcontent_box {
  padding: 50px 0;
  box-sizing: border-box;
  width: 100%;
}
.navcontent_tit {
  text-align: center;
  font-size: 28px;
  font-family: SourceHanSansCN-Bold, SourceHanSansCN;
  font-weight: bold;
  color: #072938;
  line-height: 42px;
  margin-bottom: 28px;
}
.navcontent {
  width: 1200px;
  margin: 0 auto;
  justify-content: center;
  font-family: PingFangSC-Regular, PingFang SC;
}
.navcontent_text > ul > li {
  margin-bottom: 20px;
  /* width: 18.125rem; */
  position: relative;
}
.naccontent,
.naccontent2 {
  position: absolute;
  top: 0;
  z-index: 1;
}
.naccontent {
  padding: 18px 22px 18px 13px;
  box-sizing: border-box;
  width: 230px;
}
.naccontent2 {
  padding: 18px 13px 18px 22px;
  box-sizing: border-box;
  width: 230px;
}
.naccontent_text_tit {
  font-size: 20px;
  height: 35px;
  font-weight: 600;
  color: #333333;
  line-height: 28px;
  border-bottom: 2px solid #33cdce;
  width: max-content;
}
.naccontent_text_tit2 {
  font-size: 14px;
  font-weight: 600;
  color: #33cdce;
  line-height: 16px;
  text-align: center;
}
.naccontent_text_tip {
  font-size: 16px;
  font-weight: 400;
  color: #32474a;
  line-height: 26px;
  margin-top: 10px;
}
.naccontent_text_tip2 {
  font-size: 13px;
  font-weight: 400;
  color: #32474a;
  line-height: 21px;
  margin-top: 15px;
}
.navcontent > div > img {
  width: 530px;
  /* height: 375px; */
}
.textbg {
  /* position: absolute;
  top: 0; */
  width: 230px;
  height: 150px;
}
.navcontent2 {
  margin: 0 60px;
}

#advert {
  position: relative;
}
.advertisementbg {
  width: 1920px;
  height: 580px;
}
.advertisement {
  position: absolute;
  top: 40px;
  z-index: 1;
  width: 1200px;
  left: 50%;
  transform: translate(-50%, 0);
}
.advertisement_tit > p {
  font-size: 36px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #071538;
  line-height: 38px;
  text-align: center;
  margin-top: 23px;
}
.advertisement_box {
  width: 702px;
  height: 270px;
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 0px 0px 40px 0px rgba(205, 232, 245, 0.36);
  border: 2px solid rgba(255, 255, 255, 0.55);
  backdrop-filter: blur(8px);
  margin-top: 64px;
  justify-content: center;
}
.advertisement_left {
  width: 212px;
  height: 100%;
  background: #c6edff;
  padding: 14px;
  box-sizing: border-box;
}
.advertisement_leftp_active {
  background: #33cdce;
  color: #fff;
}
.advertisement_left > p {
  font-size: 16px;
  margin-top: 10px;
  text-align: center;
  line-height: 52px;
  width: 184px;
  height: 52px;
  border-radius: 4px;
}
.advertisement_leftp {
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 0px 0px 25px 0px rgba(205, 232, 245, 0.31);
  border: 1px solid rgba(255, 255, 255, 0.55);
  backdrop-filter: blur(8px);
  color: #333;
}
.advertisement_right {
  flex: 1;
}
.advertisement_right > ul > li {
  min-height: 50px;
  border-bottom: 1px dashed #cfdcdb;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 25px;
  padding-top: 7px;
  box-sizing: border-box;
  margin-left: 28px;
  margin-right: 24px;
}
.advertisement_right > ul > li:nth-last-child(1) {
  border-bottom: none;
}
.advertisement_right > ul > li > span:nth-child(1) {
  background: #41c7c0;
  border-radius: 50%;
  width: 3px;
  height: 3px;
  line-height: 15px;
  float: left;
  margin: 10px 6px 0 -10px;
}

.personBox {
  background: #f9fcfe;
  padding: 49px 0;
  // width: 100vw;
  box-sizing: border-box;
  margin: 0 auto;
}
.person_list_box {
  width: 1200px;
  flex-wrap: wrap;
  margin: 0 auto;
  justify-content: center;
}
.person_list_box > .persion:nth-child(3n) {
  margin-right: 0;
}
.person_list_box > .persion:nth-child(3 + n) {
  margin-bottom: 0;
}
.persion {
  /* width: 250px;
  height: 150px;
  background: #ffffff;
  box-shadow: 0px 0px 31px 0px rgba(144, 160, 179, 0.16);
  border-radius: 3px;
  margin-right: 20px;
  padding: 9px 15px;
  box-sizing: border-box;
  margin-bottom: 20px; */

  margin-right: 50px;
  padding: 24px;
  box-sizing: border-box;
  margin-bottom: 30px;
  width: 380px;
  // height: 190px;
  background: #ffffff;
  box-shadow: 0px 0px 50px 0px rgba(144, 160, 179, 0.16);
  border-radius: 4px;
}
.persion_avatar_box {
  margin-bottom: 20px;
}
.persion_avatar {
  width: 66px;
  height: 66px;
}
.persion_avatar_text {
  margin-left: 16px;
}
.persion_avatar_text_p1 {
  font-size: 20px;
  font-family: SourceHanSansCN-Bold, SourceHanSansCN;
  font-weight: bold;
  color: #202125;
  line-height: 30px;
}
.persion_avatar_text_p2 {
  /* width: 155px;
  height: 25px; */
  font-size: 14px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  color: #707784;
  line-height: 20px;
}
.persion_time {
  font-size: 14px;
  font-family: SourceHanSansCN-Normal, SourceHanSansCN;
  font-weight: 400;
  color: #a1a5ad;
  line-height: 21px;
  margin-top: 4px;
}
.persion_time2 {
  /* display: -webkit-box;
  -webkit-box-orient: vertical; */
  overflow: hidden;
  height: 66px;
  max-height: 66px;
  /* -webkit-line-clamp: 3;
  text-overflow: ellipsis; */
}
.persion_time1 {
  color: #666666;
}

.enterprise {
  position: relative;
}
.enterprise_bg_big {
  width: 1920px;
  height: 806px;
  margin: 0 auto;
}
.enterprise_bg {
  width: 100%;
  // width: 1920px;
  // height: 805px;
}
.enterprise_content {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}
.enterprise_header_bth {
  color: #fff;
  margin: 22px auto 38px;
  font-weight: 500;
  font-size: 16px;
  line-height: 44px;
  text-align: center;

  width: 220px;
  height: 44px;
  background: #33cdce;
  border-radius: 4px;
}

.enterprise_content_box {
  // min-width: 210px;
  // max-width: 1000px;
  width: 1200px;
  margin: 0 auto;
}
.enterprise_li {
  width: 154px;
  height: 92px;
  margin-top: -5px;
}
.enterprise_li > p {
  line-height: 92px;
  text-align: center;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}
.enterprise_left_bg {
  width: 75px;
  height: 473px;
  left: 145px;
  top: 0;
  position: absolute;
  background: linear-gradient(
    270deg,
    rgba(207, 241, 252, 0) 0%,
    #cff1fd 85%,
    rgba(207, 241, 253, 0) 100%
  );
}
.enterprise_right_bg {
  width: 75px;
  height: 473px;
  right: -26px;
  position: absolute;
  top: 0;
  background: linear-gradient(
    -270deg,
    rgba(207, 241, 252, 0) 0%,
    #cff1fd 85%,
    rgba(207, 241, 253, 0) 100%
  );
}

.enterprise_li2 {
  width: 138px;
  height: 76px;
  background: #ffffff;
  box-shadow: 0px 0px 5px 0px rgba(204, 222, 227, 0.2);
  border-radius: 4px;
  margin-right: 10px;
  flex-wrap: nowrap;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}
.scroll_box {
  overflow-x: scroll;
  width: 1060px;
}
.enterprise_li2 > img {
  // width: 100%;
  // width: 95px;
  // height: 50px;
  width: 126px;
  height: 60px;
  margin: 0 auto;
}

.floor-5 {
  background-color: #f8fbfe;
}

.floor-5 .rc-bricks {
  width: 1200px;
  margin: 0 auto;
  position: relative;
  height: 540px;
}

.floor-5 .rc-bricks__item {
  position: absolute;
  border: 1px solid transparent;
  transition: all 0.2s;
  overflow: hidden;
  z-index: 2;
  margin-right: 50px;
  padding: 20px 19px;
  box-sizing: border-box;
  margin-bottom: 24px;
  width: 380px;
  height: 240px;
  background: #ffffff;
  box-shadow: 0px 0px 40px 0px rgba(144, 160, 179, 0.16);
  border-radius: 3px;
}
.floor-5 .rc-bricks__item:nth-child(1) {
  left: 0;
  top: 0;
  z-index: 3;
}
.floor-5 .rc-bricks__item:nth-child(2) {
  left: 410px;
  top: 0;
  z-index: 3;
}
.floor-5 .rc-bricks__item:nth-child(3) {
  left: 820px;
  top: 0;
  z-index: 3;
}
.floor-5 .rc-bricks__item:nth-child(4) {
  left: 0;
  top: 270px;
}
.floor-5 .rc-bricks__item:nth-child(5) {
  left: 410px;
  top: 270px;
}
.floor-5 .rc-bricks__item:nth-child(6) {
  left: 820px;
  top: 270px;
}
.floor-5 .rc-bricks__item:hover {
  box-shadow: 0px 4px 24px 0px rgba(124, 142, 166, 0.31);
  border: 1px solid #33cdce;
  transform: translate(0, -14px);
  height: auto;
}
.floor-5 .rc-bricks__item:hover .contact-card__statistics {
  height: auto;
}

.contact-card__statistics {
  box-sizing: border-box;
  height: 100px;
  margin: 0;
  padding: 0;
  overflow: hidden;
}
.contact-card__statistics > li {
  box-sizing: border-box;
  position: relative;
  margin: 0;
  padding: 0 4px;
  list-style: none;
  // display: flex;
  // flex-flow: row nowrap;
}
.contact-card__statistics > li > strong {
  font-size: 15px;
  font-weight: 400;
  color: #a1a5ad;
  line-height: 21px;
  flex: 1 1 100%;
}

.contact-card__statistics > li > span {
  flex: 0 0 75px;
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  line-height: 21px;
}

.qrcode_box {
  width: 180px;
  height: 180px;
  background: #f3faff;
  padding: 9px;
  box-sizing: border-box;
  margin: 0 auto;
}
.qrcode_box > .codeContent {
  width: 100%;
  height: 100%;
}

.qrcode_box > .codeTitle {
  text-align: center;
  padding-top: 20px;
  font-weight: bold;
  margin-top: 20px;
}

.qrcode_box > .codeContent {
  position: relative;
}

.qrcode_box > .refreshCode {
  position: absolute;
  top: 50px;
  left: 40px;
}
.qrcode_box > .codeContent > p {
  text-align: center;
}
</style>
