<template>
  <section id="header_banner">
    <div class="header_banner relative">
      <img src="../../assets/img/slove/solve.png" alt="" />
      <div class="solve_text absolute">
        <p>一站式线上报销解决方案</p>
        <div>
          <p>紧迎数电票时代发展、满足各行业智慧代账发展趋势</p>
          <p>直击报销痛点，助力企业提升票据管理效益，实现降本增效</p>
        </div>
        <div class="header_btn" @click="login">免费试用</div>
      </div>
    </div>
  </section>
  <section id="overview">
    <div class="overview">
      <div class="overview_tit">产品概述</div>
      <div class="overview_content">
        <div>
          <div>
            <ul class="overview_table_ul">
              <li
                class="flex"
                v-for="(item, index) in state.tableList"
                :key="index"
              >
                <div class="table_t">{{ item.table_t }}</div>
                <img
                  src="../../assets/img/slove/arrow_right_1.png"
                  alt=""
                  class="table_arrow"
                />
                <div class="table_b">
                  <ul class="flex table_ul">
                    <li
                      class="table_li"
                      v-for="(itemb, indexb) in item.table_b"
                      :key="indexb"
                      :style="'width:' + itemb.width"
                    >
                      {{ itemb.name }}
                    </li>
                  </ul>
                </div>
                <div class="table_g">
                  <ul class="flex table_ul">
                    <li
                      class="table_li"
                      v-for="(itemg, indexg) in item.table_g"
                      :key="indexg"
                      :style="'width:' + itemg.width"
                    >
                      {{ itemg.name }}
                    </li>
                  </ul>
                </div>
                <div class="table_o">
                  <ul class="flex table_ul">
                    <li
                      class="table_li"
                      v-for="(itemo, indexo) in item.table_o"
                      :key="indexo"
                      :style="'width:' + itemo.width"
                    >
                      {{ itemo.name }}
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section id="scene">
    <div class="scene">
      <p class="scene_tit">解决场景</p>
      <div class="scene_nav flex">
        <p
          @mousemove="setNavIndex(index)"
          v-for="(item, index) in state.scene_nav_list"
          :key="index"
          :class="[index == state.sceneNavIndex ? 'scene_nav_active' : '']"
        >
          {{ item }}
        </p>
      </div>
      <div class="scene_content">
        <ul>
          <li
            class="flex scene_content_li relative"
            v-for="(item, index) in state.sceneList[state.sceneNavIndex]"
            :key="index"
          >
            <div>
              <img
                src="../../assets/img/slove/cry.png"
                alt=""
                class="scene_icon"
                style="left: 12px"
              />
              <p class="scene_content_t">{{ item.left_t_1 }}</p>
              <img :src="item.img_l" alt="" class="scene_img_l" />
            </div>
            <img
              src="../../assets/img/slove/transform.png"
              alt=""
              class="transform"
            />
            <div>
              <img
                src="../../assets/img/slove/smile.png"
                alt=""
                class="scene_icon"
                style="right: 12px"
              />
              <p class="scene_content_t" v-html="item.right_t_1"></p>
              <p class="scene_content_t2" v-if="item.right_t_2">
                {{ item.right_t_2 }}
              </p>
              <img :src="item.img_r" alt="" class="scene_img_r" />
            </div>
          </li>
        </ul>
      </div>
    </div>
  </section>

  <section id="scene_code">
      <div class="scene_code relative">
          <img src="../../assets/img/slove/blue_bg.png" alt="">
          <div class="scene_code_text flex absolute">
              <p>扫码加客服企微，快速开通企业账号</p>
              <div class="code_btn" @click="login">现在注册</div>
              <div class="qrcode_box">
                  <img src="../../assets/img/slove/qrcode.png" alt="">
                  <p>微信扫码添加好友</p>
              </div>
              
          </div>
      </div>
  </section>
</template>

<script setup lang='ts'>
import getAssetsFile from "/@/assets/img/image_js/image_js";
import { reactive, watchEffect } from "vue";

const state = reactive({
  tableList: [
    {
      table_t: "产品目标",
      table_b: [{ name: "解决企业发票，费用报销电子化需求", width: "100%" }],
      table_g: [{ name: "形成企业财税电子档案", width: "100%" }],
      table_o: [{ name: "辅助决策", width: "100%" }],
    },
    {
      table_t: "用户需求",
      table_b: [
        { name: "票据采集", width: "30%" },
        { name: "费用报销", width: "30%" },
        { name: "基本配置", width: "30%" },
      ],
      table_g: [
        { name: "电子凭证", width: "45%" },
        { name: "凭证归档", width: "45%" },
      ],
      table_o: [{ name: "企业管理", width: "100%" }],
    },
    {
      table_t: "业务功能",
      table_b: [
        { name: "票据识别", width: "30%" },
        { name: "票据验真", width: "30%" },
        { name: "费用报销", width: "30%" },
        { name: "费用借支", width: "30%" },
        { name: "还款管理", width: "30%" },
        { name: "付款申请", width: "30%" },
      ],
      table_g: [
        { name: "会计核算", width: "45%" },
        { name: "多维预警", width: "45%" },
        { name: "统计分析", width: "45%" },
        { name: "电子档案", width: "45%" },
      ],
      table_o: [
        { name: "资金分析", width: "100%" },
        { name: "费用预算", width: "100%" },
      ],
    },
    {
      table_t: "系统支撑",
      table_b: [
        { name: "高新企业", width: "30%" },
        { name: "等保三级", width: "30%" },
        { name: "数据安全", width: "30%" },
      ],
      table_g: [
        { name: "规范标准", width: "45%" },
        { name: "行业资质", width: "45%" },
      ],
      table_o: [{ name: "...", width: "100%" }],
    },
  ],
  sceneNavIndex: 0,
  scene_nav_list: ["员工报销", "财务审核", "老板决策"],
  sceneList: [
    [
      {
        left_t_1: "发票太多不好找，超期找到无法报销",
        left_t_2: "",
        img_l: getAssetsFile.getAssetsFile("/slove/left_1_1.png"),
        right_t_1:
          '<span style="color:#33CDCE">创建自定义票包</span>汇总整理各种发票',
        right_t_2: "采用OCR识别技术，具备容错率高、识别准确率高等特性",
        img_r: getAssetsFile.getAssetsFile("/slove/right_1_1.png"),
      },
      {
        left_t_1: "填单太复杂，报销流程也很繁琐",
        left_t_2: "",
        img_l: getAssetsFile.getAssetsFile("/slove/left_1_2.png"),
        right_t_1:
          '<span style="color:#33CDCE">借支、报销、付款</span>三步快速发起流程',
        right_t_2: "",
        img_r: getAssetsFile.getAssetsFile("/slove/right_1_2.png"),
      },
      {
        left_t_1: "老板出差找不到，流程被卡真难报销",
        left_t_2: "",
        img_l: getAssetsFile.getAssetsFile("/slove/left_1_3.png"),
        right_t_1:
          '<span style="color:#33CDCE">微信推送单据提醒</span>上级随时随地审批',
        right_t_2: "",
        img_r: getAssetsFile.getAssetsFile("/slove/right_1_3.png"),
      },
    ],
    [
      {
        left_t_1: "月底单据堆成山，各类单据来回翻",
        left_t_2: "",
        img_l: getAssetsFile.getAssetsFile("/slove/left_2_1.png"),
        right_t_1:
          '<span style="color:#33CDCE">报销、借支、付款单  </span>已审、未审一目了然',
        right_t_2: "",
        img_r: getAssetsFile.getAssetsFile("/slove/right_2_1.png"),
      },
      {
        left_t_1: "发票太多难查验，一张一查太麻烦",
        left_t_2: "",
        img_l: getAssetsFile.getAssetsFile("/slove/left_2_2.png"),
        right_t_1:
          '<span style="color:#33CDCE">不同种类可多选  </span>一键追踪真方便',
        right_t_2: "",
        img_r: getAssetsFile.getAssetsFile("/slove/right_2_2.png"),
      },
      {
        left_t_1: "交来单据难合规，退来退去引误会",
        left_t_2: "",
        img_l: getAssetsFile.getAssetsFile("/slove/left_2_3.png"),
        right_t_1:
          '<span style="color:#33CDCE">风险、异常提醒全</span>  是否合规一眼见',
        right_t_2: "",
        img_r: getAssetsFile.getAssetsFile("/slove/right_2_3.png"),
      },
    ],
    [
      {
        left_t_1: "多种单据同时请款  张张审批太麻烦",
        left_t_2: "",
        img_l: getAssetsFile.getAssetsFile("/slove/left_3_1.png"),
        right_t_1:
          '<span style="color:#33CDCE">各种单据汇总查看  </span>  同时审批节省时间',
        right_t_2: "",
        img_r: getAssetsFile.getAssetsFile("/slove/right_3_1.png"),
      },
      {
        left_t_1: "审批一时爽，超支悔断肠",
        left_t_2: "",
        img_l: getAssetsFile.getAssetsFile("/slove/left_3_2.png"),
        right_t_1:
          '<span style="color:#33CDCE">不怕费用超预算  </span>提前设置实时查看',
        right_t_2: "",
        img_r: getAssetsFile.getAssetsFile("/slove/right_3_2.png"),
      },
      {
        left_t_1: "存款天天变，月末盘点难",
        left_t_2: "",
        img_l: getAssetsFile.getAssetsFile("/slove/left_3_3.png"),
        right_t_1:
          '<span style="color:#33CDCE">分析资金花哪里</span>  汇总统计来帮你',
        right_t_2: "",
        img_r: getAssetsFile.getAssetsFile("/slove/right_3_3.png"),
      },
    ],
  ],
});

function setNavIndex(index) {
  state.sceneNavIndex = index;
}

function login() {
  window.open('https://baoxiao666.com/pl', "_blank")
}
</script>

<style scoped>
.relative {
  position: relative;
}
.flex {
  display: flex;
  align-items: center;
}
.header_banner > img {
  width: 1920px;
  height: 420px;
  margin: 0 auto;
}
.absolute {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}
.solve_text {
  width: 1200px;
  margin: 0 auto;
  /* margin-top: 118px; */
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
}
.solve_text > p {
  font-size: 44px;
  font-weight: bold;
  color: #ffffff;
  line-height: 66px;
}
.solve_text > div:nth-child(2) {
  margin: 21px 0;
  margin-bottom: 54px;
}
.solve_text > div > p {
  font-size: 18px;
  font-weight: 400;
  color: #ffffff;
  line-height: 28px;
}
.header_btn {
  width: 150px;
  height: 40px;
  background: #ffffff;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 500;
  color: #33cdce;
  line-height: 40px;
  text-align: center;
}

.overview {
  background: #fafdfe;
  padding-bottom: 60px;
}
.overview_tit {
  font-size: 36px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #071538;
  line-height: 54px;
  margin: 79px auto 37px;
  text-align: center;
}
.overview_content {
    width: 1157px;
    height: 378px;
    background: rgba(211, 244, 255, 0.44);
    border-radius: 16px;
    margin: 0 auto;
    padding: 13px 11px;
    box-sizing: border-box;
}
.overview_content > div {
    width: 1135px;
    height: 352px;
    background: #ffffff;
    box-shadow: 0px 0px 20px 0px rgba(200, 234, 234, 0.63);
    border-radius: 8px;
    padding: 26px 36px 23px 40px;
    box-sizing: border-box;
}
.overview_content > div > div {
    width: 1059px;
    height: 303px;
    border-radius: 8px;
    border: 2px dashed #d6eaed;
    backdrop-filter: blur(8px);
}
.overview_table_ul > li {
  min-height: 63px;
  border-bottom: 2px dashed #d6eaed;
  font-family: PingFangSC-Medium, PingFang SC;
  padding: 14px 23px 0 22px;
  box-sizing: border-box;
}
.overview_table_ul > li:nth-last-child(1) {
  border: none;
}
.table_t {
width: 113px;
    height: 36px;
    background: #33cdce;
    border-radius: 18px;
    font-size: 16px;
    font-weight: 500;
    color: #ffffff;
    line-height: 36px;
    text-align: center;
    margin-bottom: 10px;
}
.table_arrow {
  width: 40px;
  height: 29px;
  margin: 0 18px 10px;
}
.table_li {
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  border-radius: 19px;
  line-height: 36px;
  height: 36px;
  margin-bottom: 10px;
}
.table_b {
  width: 363px;
}
.table_b li {
  background: #f4fdff;
  border: 1px solid #c2e3f5;
  color: #2b8cb0;
}
.table_g {
  width: 246px;
  margin: 0 20px;
}
.table_g li {
  background: #f7ffff;
  border: 1px solid #bdebe8;
  color: #28aba4;
}
.table_o {
  width: 134px;
}
.table_o li {
  background: #fffdf5;
  border: 1px solid #faeacd;
  color: #e49d42;
}
.table_ul {
  flex-wrap: wrap;
  justify-content: space-between;
}

.scene {
  background: #f6fcfe;
  padding-top: 43px;
  padding-bottom: 35px;
}
.scene_tit {
  font-size: 36px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #071538;
  line-height: 54px;
  text-align: center;
  /* margin-top: 43px; */
}
.scene_nav {
  width: 1024px;
  justify-content: space-around;
  margin: 0 auto;
  margin-top: 35px;
}
.scene_nav > p {
  /* width: 60px; */
  height: 44px;
  font-size: 24px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #071538;
  line-height: 45px;
  border-bottom: 1px solid #cddbe6;
}
.scene_nav_active {
  color: #33cdce !important;
  border-bottom: 1px solid #33cdce !important;
}
.scene_content {
  width: 1024px;
  margin: 0 auto;
  margin-top: 57px;
}
.scene_content_li {
  margin-bottom: 25px;
}
.scene_content_li > div {
  width: 512px;
  height: 430px;
  position: relative;
  padding-top: 40px;
  box-sizing: border-box;
}
.scene_content_li > div:nth-child(1) {
  background: #e2f3f5;
  border-radius: 20px 0px 0px 20px;
}
.scene_content_li > div:nth-last-child(1) {
  background: #ffffff;
  border-radius: 0px 20px 20px 0px;
}
.scene_icon {
  position: absolute;
  top: 12px;
  width: 28px;
  height: 28px;
}
.scene_content_t {
  font-size: 20px;
  font-family: SourceHanSansCN-Bold, SourceHanSansCN;
  font-weight: bold;
  color: #333333;
  line-height: 30px;
  text-align: center;
}
.scene_content_t2 {
  font-size: 8px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  color: #666666;
  line-height: 11px;
  text-align: center;
}
.transform {
  width: 96px;
  height: 96px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  z-index: 1;
}
.scene_img_l {
  width: 410px;
  height: 350px;
  margin: 0 auto;
}
.scene_img_r {
  height: 338px;
  margin: 0 auto;
}

.scene_code>img {
    /* width: 100%; */
    width: 1920px;
    height: 140px;
    margin: 0 auto;
}
.scene_code_text {
    justify-content: center;
    /* padding: 0 278px; */
    width: 1200px;
    height: 100%;
    margin: 0 auto;
    left: 50%;
    transform: translate(-50%,0);
}
.scene_code_text>p:nth-child(1) {
    /* width: 280px; */
    /* height: 18px; */
    font-size: 28px;
    font-family: SourceHanSansCN-Bold, SourceHanSansCN;
    font-weight: bold;
    color: #FFFFFF;
    line-height: 42px;
}
.code_btn {
    width: 140px;
    height: 44px;
    line-height: 44px;
    text-align: center;
    font-size: 16px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #0758F9;
    background: #FFFFFF;
    border-radius: 4px;
    margin: 0 53px;
}
.qrcode_box {
    margin-left: 200px;
    text-align: center;
}
.qrcode_box>p {
    /* width: 12px; */
    /* height: 60px; */
    font-size: 12px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 18px;
}
.qrcode_box>img {
    width: 96px;
    height: 96px;
    /* margin-left: 12px; */
}
</style>