<template>
  <section id="header_banner">
    <div class="header_banner relative">
      <img src="../../assets/img/buy/dilatation_bg.png" alt="" />
      <div class="header_banner_text">
          <div class="question_t relative"><span class=""></span><span class="absolute">扩容包购买</span></div>
          <p>您的专属票据管家，即买即用，极限成本助力线上费控。</p>
      </div>
    </div>
  </section>
  <section id="version">
    <div class="version relative">
      <ul class="flex version_ul absolute">
        <li v-for="(item, index) in versionList.data.extInvoiceList" :key="index">
          <p class="version_t">{{ item.specsName }}</p>
          <p class="version_t2">
            <span>¥</span>
            <span>{{ item.specsPrice }}</span>
            <!-- <span>/年</span> -->
          </p>
          <p class="version_t3">¥{{item.originalPrice}}</p>
          <div class="version_btn" @click="state.mouseShow=!state.mouseShow">立即购买</div>
        </li>
      </ul>
    </div>
  </section>

  <section id="questions">
      <div class="questions">
          <div class="question_t relative"><span class=""></span><span class="absolute">购买问题</span></div>
          <ul>
              <li class="question_content" v-for="(item, index) in state.question2" :key="index">
                  <p>
                    <span style="color:#33CDCE">Q：</span>
                    {{item.q}}
                  </p>
                  <p v-html="item.a"></p>
              </li>
          </ul>
      </div>
  </section>

  <div class="modules_view" v-if="state.mouseShow">
    <div>
      <p style="cursor: pointer" @click="state.mouseShow = false">×</p>
      <p><span style="font-size: 18px;font-weight:400;color:#999;">请打开手机微信扫描下方二维码</span> <br/>前往 <span style="color:#41C7C0">票靓小程序</span>下单付款</p>
      <img src="../../assets/img/expandPackage.png" alt="" />
      <!-- <div @click="state.mouseShow = false">关闭</div> -->
    </div>
  </div>

  <bottomdiv></bottomdiv>
</template>

<script setup lang="ts">
import bottomdiv from './buybottom.vue';
// import registration from "./registration.vue";

import { reactive, watchEffect } from "vue";
import getAssetsFile from "/@/assets/img/image_js/image_js";
import axios from "/@/utils/request";


const state = reactive({
  versionList: [],
  question2:[
      {
          q:'如何购买和使用权益套餐?',
          a:'票靓的企业版VIP套餐购买需要先创建企业平台账号，票靓提供企业平台账号级别的统一企业管理功能。为企业平台账号购买套餐成功后，可直接进行使用，无需安装，即可实现随时移动报销。'
      },
      {
          q:'订单支持哪些付款方式?',
          a:'订单付款方式包括微信支付和对公付款。<br/>微信支付：订单有效期内在线通过微信支付即可完成。<br/>对公付款：若您采用对公付款的方式，请添加我们的客服企业微信，并于订单有效期内完成对公支付，在对公付款时备注您的订单编号。将订单编号及对公支付的信息发给客服，我们将为您处理。'
      },
      {
          q:'如何获取发票?',
          a:'票靓提供增值税电子普通发票和增值税专用发票。如您需要获取增值税专用发票，请联系客服。如您需要获取增值税电子普通发票，请在确认订单时选择开具发票，或在订单完成后，在个人中心-发票服务功能开具发票。'
      },
  ],
  mouseShow: false,

});

// let packageQueryResult: any = reactive({ data: {} });
// let mouseShow = reactive({
//   mouseShow: false,
//   listActive: 0,
//   scrollStart: false,
//   scrollLeft: 0,
// });
let versionList: any = reactive({ data: {} });

axios
  .get("/order/flow/extInvoiceQuery?userType=2")
  .then((res) => {
    versionList.data = res.result;
  })
  .catch((err) => {
    console.log(err);
  });
</script>

<style scoped lang='less'>
div,
li,
p,
span {
  cursor: context-menu;
}
.flex {
  display: flex;
}
.relative {
  position: relative;
}
.relative > img {
  width: 100%;
}
.relative > div {
  position: absolute;
  /* top: 0;
  width: 100%;
  height: 100%; */
  z-index: 1;
}
.absolute {
  position: absolute;
}

#businesscard {
  padding-bottom: 75px;
  padding-top: 20px;
  background: #f9fdff;
}
.version {
  height: 200px;
  background: #f9fdff;
}
.version_ul {
  justify-content: space-between;
  width: 1200px;
  top: -64px;
  left: 50%;
  transform: translate(-50%, 0);
}
.version_ul > li {
  padding: 30px 54px;
  box-sizing: border-box;
  width: 276px;
  height: 260px;
  background: #FFFFFF;
  box-shadow: 0px 0px 40px 0px rgba(34,34,34,0.05);
}
.version_ul > li > p {
  text-align: center;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
}
.version_t {
  font-size: 24px;
  font-weight: 500;
  color: #27364e;
  line-height: 33px;
  margin-top: 10px;
}
// .version_t2 {
//   margin: 5px 0;
// }
.version_t2 > span:nth-child(1) {
  font-size: 32px;
  font-family: DINAlternate-Bold, DINAlternate;
  font-weight: bold;
  color: #27364e;
}
.version_t2 > span:nth-child(2) {
  font-size: 48px;
  font-family: DINAlternate-Bold, DINAlternate;
  font-weight: bold;
  color: #27364e;
}
.version_t2 > span:nth-child(3) {
  font-size: 14px;
  font-weight: 400;
  color: #999;
  line-height: 20px;
  margin: 12px 0;
}
.version_t3 {
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    line-height: 28px;
    text-decoration: line-through;
}
/* .version_li {
    justify-content: space-between;
} */
.version_li > div {
  justify-content: space-between;
}
.version_li > div > p {
  font-size: 9px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  line-height: 18px;
}
.version_li > div > p:nth-child(1) {
  color: #666666;
}
.version_li > div > p:nth-child(2) {
  color: #999999;
}

.version_btn {
  width: 168px;
  height: 42px;
  border-radius: 4px;
  margin: 0 auto;
  margin-top: 16px;
  line-height: 42px;
  text-align: center;
  font-size: 16px;
border: 1px solid #33CDCE;
color: #33CDCE;
}
.version_btn:hover {
  background: #33CDCE;
  color: #ffffff;
}

.businesscard_tit {
  margin-bottom: 20px;
  font-size: 18px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 25px;
  text-align: center;
}

.questions {
    width: 1200px;
    margin: 0 auto;
    padding-top: 60px;
}
#version,#questions {
    background: #F9FDFF;
}
.question_t {
    width: 144px;
    margin: 85px auto;
}
.question_t>span:nth-child(1) {
    display: inline-block;
    width: 150px;
    height: 6px;
    background: linear-gradient(138deg, #C1FAF7 0%, #33CDCE 100%);
}
.question_t>span:nth-child(2) {
    display: inline-block;
    left: 0;
    bottom: 4px;
    font-size: 36px;
    font-family: SourceHanSansCN-Bold, SourceHanSansCN;
    font-weight: bold;
    color: #27364E;
    line-height: 54px;
}

.question_img_box {
    width: 1200px;
    margin: 0 auto;
}

.question_img_ul {
    flex-wrap: wrap;
    justify-content: space-between;
}
.question_img_li {
    width: 45%;
    align-items: flex-start;
}
.question_img {
    width: 54px;
    height: 54px;
    margin-right: 27px;
}
.question_img_right>p {
    width: 140px;
    height: 45px;
    text-align: center;
    font-size: 22px;
    font-family: SourceHanSansCN-Bold, SourceHanSansCN;
    font-weight: bold;
    color: #202125;
    line-height: 50px;
    border-bottom: 2px solid #33CDCE;
}
.question_img_right>ul {
    border-top: 1px solid #E2EAF2;
    padding: 10px 0;
    box-sizing: border-box;
}
.question_img_right>ul>li {
    font-size: 14px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #6C6C6C;
    line-height: 22px;
}

.question_content>p:nth-child(1) {
    font-size: 20px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: bold;
    color: #202125;
    line-height: 30px;
    margin-top: 20px;
}
.question_content>p:nth-child(2) {
    font-size: 16px;
    font-family: SourceHanSansCN-Normal, SourceHanSansCN;
    font-weight: 400;
    color: #666666;
    line-height: 24px;
    margin-top: 12px;
}


.header_banner>img {
  width: 1920px;
  height: 400px;
  margin: 0 auto;
}
.header_banner_text {
    padding-top: 57px;
    box-sizing: border-box;
    width: 1200px;
    margin: 0 auto;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
}
.header_banner_text .question_t {
    font-size: 40px;
    width: 200px;
    margin: 40px 0 20px;
}
.header_banner_text .question_t>span:nth-child(1) {
    width: 224px;
    height: 14px;
    background: linear-gradient(90deg, #41C7C0 0%, rgba(190,246,244,0) 100%);
}
.header_banner_text .question_t>span:nth-child(2)  {
    left: 0;
    bottom: 13px;
    font-size: 40px;
}
.header_banner_text>p {
    font-size: 15px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: bold;
    color: #125386;
    line-height: 23px;
    /* text-align: center; */
}
.header_banner_text>p:nth-child(2) {
    margin: 26px 0 20px;
    font-size: 16px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #27364E !important;
    line-height: 24px;
}
/* .header_banner_text>p:nth-last-child(1) {
    font-weight: 400 !important;
    color: #297EC9 !important;
} */


.modules_view {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 11;
  > div {
    width: 420px;
    height: 350px;
    margin-top: 15%;
    margin-left: calc(50% - 300px);
    background: #fff;
    border-radius: 8px;
    text-align: center;
    > p:nth-child(1) {
      text-align: right;
      padding: 5px 20px;
      font-size: 30px;
      height: 30px;
      color: #666;
    }
    > p:nth-child(2) {
      margin: 10px 0;
      font-size: 20px;
      margin-top: 0;
    }
    > img {
      margin: 20px auto;
      width: 200px;
      height: 200px;
    }
    
    >div {
      width: 200px;
      height: 44px;
      border-radius: 4px;
      border: 1px solid #C0C0C0;
      line-height: 44px;
      text-align: center;
      font-size: 16px;
      color: #838383;
      margin: 0 auto;
    }
  }
}
</style>