<template>
  <div style="background: #fafdfe">
    <div class="introduce">
      <div class="header">
        <p>您的专属票据管家</p>
        <div class="flex">
          ———
          <p>多版本满足不同用户需求</p>
          ———
        </div>
      </div>
      <div class="content flex">
        <div
          style="
            box-shadow: 0px 10px 50px 0px rgba(126, 177, 198, 0.2),inset 0px 2px 0px 0px #ee8f36;
          "
        >
          <div class="version_title">票靓·专业版</div>
          <div class="version_tit">全面企业费控系统</div>
          <ul class="version_list">
            <li>
              <span></span>不止发票及费控管理，更涵盖<span
                style="font-weight: bold"
                >企业开票、认证、财务管理</span
              >等功能。
            </li>
            <li>
              <span></span>支持<span style="font-weight: bold">全电发票</span
              >涉税业务流程和企业商业行为的融合，满足多场景、规模化、自动化的业务需求。
            </li>
            <li>
              <span></span>助力企业<span style="font-weight: bold"
                >智慧税务和票税数字化</span
              >实现。
            </li>
          </ul>
          <div class="btn" @click="show4Fn">免费试用</div>
        </div>
        <div
          style="
            box-shadow: 0px 10px 50px 0px rgba(126, 177, 198, 0.2),inset 0px 2px 0px 0px #33cdce;
          "
        >
          <div class="version_title">票靓·个人版</div>
          <div class="version_tit">个人票据管理中心</div>
          <ul class="version_list">
            <li>
              <span></span>支持发票和费控管理，<span style="font-weight: bold"
                >独立开票、发票查验、票包管理</span
              >和<span style="font-weight: bold">申请单据。</span>
            </li>
            <li>
              <span></span
              >无需加入企业也能享有自己独立的票仓容量，并且票靓还会每月<span
                style="font-weight: bold"
                >额外赠送</span
              >个人版的免费票仓。
            </li>
            <li>
              <span></span>建立个人记账本，个人费用分析<span
                style="font-weight: bold"
                >一目了然</span
              >。
            </li>
          </ul>
          <div class="btn" @click="state.show3 = true">立即使用</div>
        </div>
        <div
          style="
            box-shadow: 0px 10px 50px 0px rgba(126, 177, 198, 0.2),inset 0px 2px 0px 0px #5c97dd;
          "
        >
          <div class="version_title">私有化部署</div>
          <div class="version_tit">企业专属的部署方案</div>
          <ul class="version_list">
            <li>
              <span></span>票靓支持对本产品所有功能进行企业<span
                style="font-weight: bold"
                >私有化部署/个性化定制</span
              >。
            </li>
            <li>
              <span></span>支持企业系统集成、可<span style="font-weight: bold"
                >专属定制</span
              >业务功能、具有丰富的数据接口、专享<span style="font-weight: bold"
                >数据私有化</span
              >方案。
            </li>
            <li>
              <span></span>详情请咨询客服，享受<span style="font-weight: bold"
                >一对一专属服务</span
              >支持。
            </li>
          </ul>
          <div class="btn" @click="mikecrm">咨询客服</div>
        </div>
      </div>
    </div>
    <!-- 小程序码 -->
    <div class="program">
      <transition name="plus-icon">
        <div class="fixed-left program-left" v-if="state.show3">
          <p style="cursor: pointer" @click="state.show3 = false">×</p>
          <p>请扫描小程序二维码进行体验</p>
          <img
            src="../../assets/img/fixed-img6.jpg"
            width="260"
            height="260"
            alt=""
          />
        </div>
      </transition>
    </div>

    <!-- 小程序码 -->
    <div class="program">
      <transition name="plus-icon">
        <div class="fixed-left program-left" v-show="state.show4" style="height:350px;width:420px;border-radius:8px">
          <p style="cursor: pointer" @click="state.show4 = false">×</p>
          <p>请使用微信扫一扫注册</p>
          <div class="qrcode_box">
            <div class="codeContent">
              <div :class="{ qrCodeOpacity: state.qrCodeTimeOut }" class="code">
                <qrcode
                  :url="state.qrCodeUrl"
                  style="text-align: center;"
                  ref="qrcode"
                />
              </div>
              <!-- <div class="refreshCode"> -->
                <div class="refreshCode" v-show="state.qrCodeTimeOut">
                <p style="font-weight: bold">二维码已失效</p>
                <p>
                  请<span
                    style="color: #41c7c0; cursor: pointer"
                    @click="refreshQrcode"
                    >刷新
                    <i class="vxe-icon--refresh"></i>
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>


<script setup lang="ts">
import { useRouter } from "vue-router";
import { onBeforeUnmount, reactive, watchEffect,ref } from "vue";
import Bus from "/@/utils/bus";
import { ROUTER_CACHE_KEY } from "/@/enums/cacheEnum";
import Qrcode from "/@/components/Qrcode/Qrcode.vue";
import axios from "/@/utils/request";

const state = reactive({
  show3: false,

  show4:false,
  qrCodeUrl: "https://baoxiao666.com/hwsoft/weappAuthLogin?token=123456", //扫码登录小程序测试环境
  qrCodeUuid: "123456",
  qrCodeTimeOut: true,
  checkTimer:setTimeout(() => {
    console.log("检测到二维码超时失效");
  }, 120000)
});

function login() {
  window.open("https://baoxiao666.com/pl", "_blank");
}
function mikecrm() {
  window.open("http://seapower.mikecrm.com/udpwVLB", "_blank");
}
// refreshQrcode()
/**
 * 随机生成数字
 *
 * 示例：生成长度为 12 的随机数：randomNumber(12)
 * 示例：生成 3~23 之间的随机数：randomNumber(3, 23)
 *
 * @param1 最小值 | 长度
 * @param2 最大值
 * @return int 生成后的数字
 */
function randomNumber(min1,max1) {
  // 生成 最小值 到 最大值 区间的随机数
  const random = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1) + min);
  };
  if (arguments.length === 1) {
    let [length] = arguments;
    // 生成指定长度的随机数字，首位一定不是 0
    let nums = [...Array(length).keys()].map((i) =>
      i > 0 ? random(0, 9) : random(1, 9)
    );
    return parseInt(nums.join(""));
  } else if (arguments.length >= 2) {
    let [min, max] = arguments;
    return random(min, max);
  } else {
    return Number.NaN;
  }
}

/**
 * 随机生成字符串
 * @param length 字符串的长度
 * @param chats 可选字符串区间（只会生成传入的字符串中的字符）
 * @return string 生成的字符串
 */
function randomString(length, chats) {
  if (!length) length = 1;
  if (!chats) chats = "0123456789qwertyuioplkjhgfdsazxcvbnm";
  let str = "";
  for (let i = 0; i < length; i++) {
    let num = randomNumber(0, chats.length - 1);
    str += chats[num];
  }
  return str;
}
/**
 * 随机生成uuid
 * string 生成的uuid
 */
function randomUUID() {
  let chats = "0123456789abcdef";
  return randomString(32, chats);
}
const qrcode=ref(null);
function refreshQrcode() {
  let that = state;
  let qrCodeAuthURL = "https://wechat.hwfapiao.com/prodWeappAuthLogin?token=";
  if (qrCodeAuthURL) {
    state.qrCodeUuid = randomUUID();
    state.qrCodeUrl = qrCodeAuthURL + state.qrCodeUuid;
  } else {
    state.qrCodeUuid = "123456";
    state.qrCodeUrl =
      "https://wechat.hwfapiao.com/hwsoft/weappAuthLogin?token=123456"; //扫码登录小程序测试环境
  }
  // console.log(qrcode.value.qrcode());
  // qrcode.value.qrcode(state.qrCodeUrl)
  let qrcodeValue = qrcode.value!=null?qrcode.value:{qrcode:function(url){console.log(url)}}
  qrcodeValue.qrcode(state.qrCodeUrl)
  console.log(state.qrCodeUrl);
  state.qrCodeTimeOut = false;
  clearTimeout(state.checkTimer);
  state.checkTimer = setTimeout(() => {
    console.log("检测到二维码超时失效");
    // that.qrCodeTimeOut = true;
    state.qrCodeUrl = "二维码已超时";
    state.qrCodeUuid = "";
    qrcodeValue.qrcode('二维码已超时')
    // qrcode.value.qrcode('二维码已超时')
  }, 120000);
}
function show4Fn() {
  state.show4 = true
  refreshQrcode()
}
</script>

<style scoped>
.introduce {
  padding: 70px 0;
  /* padding-top: 67px; */
  box-sizing: border-box;
  width: 1200px;
  margin: 0 auto;
  background: #fafdfe;
}
.header {
  text-align: center;
  margin: 0 0 20px;
  /* margin-bottom: 20px; */
}
.header > p {
  font-size: 36px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #071538;
  line-height: 38px;
}
.header > div {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #45576d;
  line-height: 16px;
  margin-top: 17px;
}
.flex {
  display: flex;
  align-items: center;
  justify-content: center;
}
.content {
  margin-top: 30px;
}
.content > div {
  width: 360px;
  height: 370px;
  background: #ffffff;
  border-radius: 8px;

  text-align: center;
  padding: 31px 14px;
  box-sizing: border-box;
  margin-right: 50px;
  transition: all 0.2s;
}
.content > div:nth-child(1):hover {
  box-shadow: 0px 4px 24px 0px rgba(126, 177, 198, 0.5), inset 0px 2px 0px 0px #ee8f36 !important;
  transform: translate(0, -14px);
}
.content > div:nth-child(2):hover {
  box-shadow: 0px 4px 24px 0px rgba(126, 177, 198, 0.5),inset 0px 2px 0px 0px #33cdce !important;
  transform: translate(0, -14px);
}
.content > div:nth-child(3):hover {
  box-shadow: 0px 4px 24px 0px rgba(126, 177, 198, 0.5), inset 0px 2px 0px 0px #5c97dd !important;
  transform: translate(0, -14px);
}
.content > div:nth-last-child(1) {
  margin-right: 0;
}
.version_title {
  font-size: 26px;
  font-family: SourceHanSansCN-Bold, SourceHanSansCN;
  font-weight: bold;
  color: #072938;
  line-height: 39px;
}
.version_tit {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #072938;
  line-height: 22px;
  margin-top: 12px;
}
.version_list {
  font-size: 14px;
  font-family: SourceHanSansCN-Normal, SourceHanSansCN;
  font-weight: 400;
  color: #333333;
  line-height: 24px;
  text-align: left;
  margin-top: 18px;
}
.version_list > li {
  padding-left: 14px;
  overflow: hidden;
  margin-top: 8px;
}
.version_list > li > span:nth-child(1) {
  background: #41c7c0;
  border-radius: 50%;
  width: 6px;
  height: 6px;
  line-height: 20px;
  float: left;
  margin: 10px 6px 0 -10px;
}
.btn {
  width: 180px;
  height: 40px;
  border-radius: 4px;
  border: 1px solid #33cdce;
  color: #41c7c0;
  font-size: 16px;
  line-height: 40px;
  text-align: center;
  margin: 40px auto 0;
}

.btn:hover {
  color: #fff;
  background: #33cdce;
}

.code {
  display: flex;
  align-items: center;
  /*margin-bottom: 15px;*/
  /*height: 169px;*/
}
.program {
  display: flex;
  align-items: center;
  /*height: 169px;*/
}
.fixed-left {
  padding: 16px;
  width: 460px;
  background: #ffffff;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.3),
    0px 4px 10px 0px rgba(0, 0, 0, 0.3), 0px 4px 10px 0px rgba(0, 0, 0, 0.3);
}
.message-left {
  position: fixed;
  /* bottom: 373px;
  right: 104px; */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100px;
}
.code-left {
  position: fixed;
  /* bottom: 193px;
  right: 104px; */

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /*height: 169px;*/
}
.program-left {
  position: fixed;
  /* bottom: 14px;
  right: 104px; */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /*height: 169px;*/
  z-index: 13;
}
.fixed-left p {
  /* margin-left: 57px; */
  font-size: 26px;
  text-align: center;
  height: 40px;
  line-height: 40px;
  font-family: SourceHanSansCN-Regular;
  color: #797979;
}
.message-left p:nth-child(1) {
  margin-top: 20px;
  font-size: 14px;
}
.message-left p:nth-child(2) {
  /* margin-top: 20px; */
  font-size: 18px;
  margin-left: 100px;
}
.code-left p {
  font-size: 14px;
  margin-left: 31px;
  margin-top: 4px;
}
.code-left .code-img {
  display: flex;
}
.code-left .code-img1 {
  /* display: flex;
  justify-content: center;
  align-items: center; */
  width: 120px;
  height: 120px;
  margin-left: 45px;
  /* background-image: url(../../assets/img/fixed-img4.png); */
}
.code-left .code-img2 {
  width: 120px;
  height: 120px;
  margin-left: 10px;
}
.program-left p {
  font-size: 22px;
  color: #333;
}
.program-left img {
  /* margin-left: 105px; */
  margin: 0 auto;
}
.fixed-right {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 61px;
  height: 61px;
  border-radius: 50%;
  background: #07b9b9;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.16);
}
.fixed-right:hover {
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.3),
    1px 4px 10px 0px rgba(0, 0, 0, 0.3), 0px 4px 10px 0px rgba(0, 0, 0, 0.3);
}
.message-right {
  position: fixed;
  bottom: 392px;
  right: 37px;
}
.code-right {
  position: fixed;
  bottom: 249px;
  right: 37px;
}
.program-right {
  position: fixed;
  bottom: 70px;
  right: 37px;
}
.program-right img {
  width: 25px;
}
.boult1 {
  position: fixed;
  right: 5px;
  bottom: 410px;
}
.boult2 {
  position: fixed;
  right: 5px;
  bottom: 261px;
}
.boult3 {
  position: fixed;
  right: 5px;
  bottom: 81px;
}
.plus-icon-enter-active {
  transition: opacity 0.7s;
}
.plus-icon-enter-from {
  opacity: 0;
}

.plus-icon-leave-active {
  transition: opacity 0.7s;
}
.plus-icon-leave-to {
  opacity: 0;
}
.program-left > p:nth-child(1) {
  text-align: right;
  font-size: 30px;
  height: 30px;
  text-align: right;
  margin-top: -10px;
}

.qrcode_box {
  width: 200px;
  height: 200px;
  background: #f3faff;
  padding: 9px;
  box-sizing: border-box;
  margin: 20px auto 0;
}
.qrcode_box > .codeContent {
  width: 100%;
  height: 100%;
}

.qrcode_box > .codeTitle {
  text-align: center;
  padding-top: 20px;
  font-weight: bold;
  margin-top: 20px;
}

.qrcode_box > .codeContent {
  position: relative;
}

.qrcode_box > .refreshCode {
  position: absolute;
  top: 50px;
  left: 40px;
}
.qrcode_box > .codeContent > p {
  text-align: center;
}
</style>